import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import NativeSelect from '@material-ui/core/NativeSelect'
import InputLabel from '@material-ui/core/InputLabel'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { ChevronLeft, CreditCard } from '@material-ui/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GROUP } from '../../config/environment'

import Withdraw from './Withdraw'

import '../../utils/css/form/_form.sass'

const isEmpty = require('lodash.isempty')

const styles = theme => ({
  formControl: {
    marginTop: 2 * theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

const dark = createMuiTheme({
  palette: {
    primary: { main: '#ffffff' }
  },
  typography: {
    useNextVariants: true,
  },
})

const CompetitionGroup = props => {
  return (
    <FormControl fullWidth className={props.classes.formControl} disabled>
      <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
      <NativeSelect inputProps={{ name: props.name, id: props.name }}
        value={props.value}
        onChange={props.onChange}
      >
        {
          props.values.map((item, index) =>
            <option key={item} value={item}>{GROUP[item]}</option>
          )
        }
        {
          !props.values.includes(String(props.value)) &&
          <option value={props.value}>{GROUP[props.value]}</option>
        }
      </NativeSelect>
    </FormControl>

  )
}

const EquipmentSelection = props => {
  const maxNumArray = []
  for (let i = 0; i <= props.max; i++) maxNumArray.push(i)

  return (
    <FormControl fullWidth className={props.classes.formControl}>
      <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
      <NativeSelect inputProps={{ name: props.name, id: props.name }}
        value={props.value}
        onChange={props.onChange}
      >
        {
          maxNumArray.map(value =>
            <option key={props.label + value} value={value}>{value}</option>
          )
        }
      </NativeSelect>
    </FormControl>
  )
}

const Members = props => {
  if (!props.numOfPartner) return null

  return (
    <React.Fragment>
      {
        props.members.map((member, index) =>
          <TextField label="參賽夥伴" name="name" key={`member-${index}`}
            fullWidth className={props.classes.formControl}
            value={member}
            onChange={(e) => props.onChange(e, index)}
            placeholder="學校 / 姓名 / 負責樂器"
            disabled
          // InputProps={{
          //   endAdornment: (
          //     <InputAdornment position="end">
          //       <React.Fragment>
          //         {
          //           props.members &&
          //           props.members.length === index + 1 &&
          //           props.members.length !== props.numOfPartner &&
          //           <IconButton onClick={props.onAddMembers} >
          //             <AddCircle />
          //           </IconButton>
          //         }
          //         <IconButton onClick={() => props.onSubMeber(index)} >
          //           <Cancel />
          //         </IconButton>
          //       </React.Fragment>
          //     </InputAdornment>
          //   ),
          // }}
          />
        )
      }
    </React.Fragment>
  )
}

const RemindMsg = props => {
  return (
    <div className="remind">
      你正在修改第
      <strong>{props.targetSchool.period}</strong>
      屆
      <strong>{props.targetSchool.name}</strong>
      報名表單
      </div>
  )
}

class FormUpdate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpenWithdrawModal: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.setWithdrawModal = this.setWithdrawModal.bind(this)
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.setWaitingState()
    this.props.handleSubmit()
  }

  handleKeyDown(e) {
    if (e.key === 'Enter' && e.target.tagName !== 'TEXTAREA') {
      e.preventDefault()
    }
  }

  setWithdrawModal(state) {
    this.setState({ isOpenWithdrawModal: state })
  }

  render() {
    const { classes } = this.props;

    return (
      <div className="page form">
        <div className="bg-cover mask" style={{ backgroundImage: `url(${this.props.coverPhoto})` }}></div>
        <div className="container">
          <form
            onSubmit={this.handleSubmit}
            onKeyDown={this.handleKeyDown}
          >
            <div className="cancel">
              <MuiThemeProvider theme={dark}>
                <Button color="primary" component={Link} to="/formlist">
                  <ChevronLeft fontSize="large" />
                  返回
                </Button>
                {
                  this.props.paymentInfo &&
                  <Button color="primary" onClick={() => this.props.handleRedirect("isRedirectToPayment")}>
                    <CreditCard />
                    繳費資訊
                  </Button>
                }
              </MuiThemeProvider>
            </div>
            <RemindMsg targetSchool={this.props.targetSchool} />
            <Stepper activeStep={this.props.activeStep} orientation="vertical" className='stepper'>
              <Step>
                <StepLabel>參賽者資訊</StepLabel>
                <StepContent>
                  <small>如資料有誤，請至【我】更新個人資訊。</small>
                  <TextField label="姓名" name="name" disabled={true}
                    fullWidth className={classes.formControl}
                    value={this.props.userInfo.name === null ? this.props.name : this.props.userInfo.name}
                  />
                  <TextField label="電話" name="phone" disabled={true}
                    fullWidth className={classes.formControl}
                    value={this.props.userInfo.phone === null ? this.props.phone : this.props.userInfo.phone}
                  />
                  <TextField label="聯絡信箱" name="email" disabled={true}
                    fullWidth className={classes.formControl}
                    value={this.props.userInfo.email === null ? this.props.email : this.props.userInfo.email}
                  />
                  {
                    this.props.userInfo.school &&
                    this.props.userInfo.school.level !== 3 &&
                    <TextField label="學校" name="school" disabled={true}
                      fullWidth className={classes.formControl}
                      value={this.props.userInfo.school.name}
                    />
                  }
                  <div className={classes.formControl}>
                    <Button variant="contained" color="primary" onClick={this.props.handleNext}>
                      確認無誤
                    </Button>
                  </div>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>報名資訊</StepLabel>
                <StepContent>
                  <CompetitionGroup label="參賽組別" name="competitionGroup"
                    value={this.props.competitionGroup}
                    values={this.props.competitionGroups} onChange={this.props.handleGroupChange}
                    classes={classes}
                  />
                  <TextField label="表演曲目" name="song" required
                    fullWidth className={classes.formControl}
                    value={this.props.song} onChange={this.props.handleInputChange}
                  />
                  <Members label="參賽夥伴"
                    members={this.props.members}
                    numOfPartner={this.props.numOfPartner}
                    onAddMembers={this.props.handleAddMembers}
                    onSubMeber={this.props.handleSubMeber}
                    onChange={this.props.handleMemberChange}
                    classes={classes}
                  />
                  {
                    this.props.maxMic !== 0 &&
                    <EquipmentSelection label="器材 - 麥克風" name="mic" max={this.props.maxMic}
                      value={this.props.mic} onChange={this.props.handleInputChange}
                      classes={classes}
                    />
                  }
                  {
                    this.props.maxMicStand !== 0 &&
                    <EquipmentSelection label="器材 - 麥克風架" name="micStand" max={this.props.maxMicStand}
                      value={this.props.micStand} onChange={this.props.handleInputChange}
                      classes={classes}
                    />
                  }
                  {
                    this.props.maxGuitar !== 0 &&
                    <EquipmentSelection label="器材 - 吉他" name="guitar" max={this.props.maxGuitar}
                      value={this.props.guitar} onChange={this.props.handleInputChange}
                      classes={classes}
                    />
                  }
                  {
                    this.props.maxBass !== 0 &&
                    <EquipmentSelection label="器材 - Bass" name="bass" max={this.props.maxBass}
                      value={this.props.bass} onChange={this.props.handleInputChange}
                      classes={classes}
                    />
                  }
                  {
                    this.props.maxCajon !== 0 &&
                    <EquipmentSelection label="器材 - Cajon" name="cajon" max={this.props.maxCajon}
                      value={this.props.cajon} onChange={this.props.handleInputChange}
                      classes={classes}
                    />
                  }
                  {
                    this.props.maxKB !== 0 &&
                    <EquipmentSelection label="器材 - KB" name="KB" max={this.props.maxKB}
                      value={this.props.KB} onChange={this.props.handleInputChange}
                      classes={classes}
                    />
                  }
                  <EquipmentSelection label="器材 - 椅子" name="chair" max={6}
                    value={this.props.chair} onChange={this.props.handleInputChange}
                    classes={classes}
                  />
                  <TextField label="備註" name="other" placeholder="如 : 主辦琴* 1、手拔 * 1"
                    fullWidth className={classes.formControl}
                    value={this.props.other} onChange={this.props.handleInputChange}
                  />
                  {
                    this.props.isEffector &&
                    <TextField label="效果器" name="effector" placeholder="如：Zoom A3、Strymon BlueSky Reverb"
                      fullWidth className={classes.formControl}
                      value={this.props.effector} onChange={this.props.handleInputChange}
                    />
                  }
                  {
                    !this.props.isECPay &&
                    <TextField label="匯款帳號後五碼" name="last5Code" type="tel"
                      fullWidth className={classes.formControl}
                      value={this.props.last5Code} onChange={this.props.handleInputChange}
                      maxLength={5}
                      readOnly={this.props.isPay ? true : false}
                    />
                  }
                  <div className={this.props.isPay ? 'payment paid' : 'payment'} >
                    <div className="row no-gutters align-items-center">
                      <div className="col-auto">
                        <div className="icon">
                          <FontAwesomeIcon icon={this.props.isPay ? 'check-circle' : 'money-check-alt'} />
                        </div>
                      </div>
                      <div className="col">
                        <div className="text">{this.props.isPay ? '已繳費' : '尚未繳費'}</div>
                        {
                          !this.props.isECPay && this.props.isPay &&
                          <div className="last5Code">
                            匯款帳號後五碼:
	                            <span className="code"> {this.props.last5Code}</span>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  {
                    this.props.commonField &&
                    this.props.commonField.length !== 0 &&
                    this.props.commonField.map((field, index) =>
                      <TextField key={`commonField${index}`} label={field.label} value={this.props.commonFieldValues[index]}
                        required={field.isRequired}
                        fullWidth className={classes.formControl}
                        onChange={(e) => this.props.handleCommonFieldChange(e, index)}
                        multiline={field.isMultiLine}
                        rows="3" rowsMax="6"
                      />
                    )
                  }
                  {
                    this.props.customField &&
                    this.props.customField.length !== 0 &&
                    this.props.customField.map((field, index) =>
                      <TextField key={`customField${index}`} label={field.label} value={this.props.customFieldValues[index]}
                        required={field.isRequired}
                        fullWidth className={classes.formControl}
                        onChange={(e) => this.props.handleCustomFieldChange(e, index)}
                        multiline={field.isMultiLine}
                        rows="3" rowsMax="6"
                      />
                    )
                  }
                  <div className={classes.formControl + " d-flex justify-content-between"}>
                    <div>
                      <Button onClick={this.props.handleBack} disabled={this.props.isWaiting}>返回</Button>
                      <Button type="submit" variant="contained" color="primary" disabled={this.props.isWaiting}>
                        {
                          this.props.isWaiting ?
                            <CircularProgress size={20} thickness={4} className="text-white" />
                            :
                            "更新"
                        }
                      </Button>
                    </div>
                    <div>
                      <Button onClick={() => this.setWithdrawModal(true)}
                        disabled={+new Date() > this.props.targetSchool.participateDeadline}
                      >
                        棄賽
                        </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
              {
                (
                  // 有金流合作院校，但尚未取得繳費代碼
                  this.props.targetSchool.isECPay &&
                  this.props.fee !== 0 &&
                  isEmpty(this.props.paymentInfo)
                ) ?
                  <Step>
                    <StepLabel>繳費代碼</StepLabel>
                    <StepContent>
                      {
                        +new Date() > this.props.targetSchool.participateDeadline ?
                          <React.Fragment>
                            <div>已超過報名期限，無法取得繳費代碼，請聯繫相關主辦單位。</div>
                            <div className={classes.formControl}>
                              <Button variant="contained" color="primary" component={Link} to="/formlist">報名資訊</Button>
                            </div>
                          </React.Fragment>
                          :
                          <div className={classes.formControl}>
                            <Button variant="contained" color="primary" onClick={this.props.getPaymentInfo}
                              disabled={this.props.isWaiting}
                            >
                              {
                                this.props.isWaiting ?
                                  <CircularProgress size={20} thickness={4} className="text-white" /> : "取得繳費代碼"
                              }
                            </Button>
                          </div>
                      }
                    </StepContent>
                  </Step>
                  :
                  // 沒有金流合作直接導回報名資訊
                  <Step>
                    <StepLabel>更新完成</StepLabel>
                    <StepContent>
                      <div>如需更改表單內容</div>
                      <div>可直接於 <strong>[我] > [報名資訊]</strong> 更新！</div>
                      <div className={classes.formControl}>
                        <Button variant="contained" color="primary" component={Link} to="/formlist">
                          報名資訊
                        </Button>
                      </div>
                    </StepContent>
                  </Step>
              }
            </Stepper>
          </form>
          <Withdraw isOpen={this.state.isOpenWithdrawModal}
            setWithdrawModal={this.setWithdrawModal}
            withdraw={this.props.withdraw}
            isWaiting={this.props.isWaiting}
          />
        </div>
      </div>
    )
  }
}

FormUpdate.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FormUpdate);