import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import { ChevronLeft } from '@material-ui/icons'
import { Loader } from '../../utils/component/component'

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

class QA extends React.Component {
  render() {
    const { classes, data } = this.props

    return (
      <div className="page setting-qa">
        <div className="container">
          <div className="top-bar">
            <IconButton component={Link} to={`/setting`} className="icon" >
              <ChevronLeft />
            </IconButton>
            <b className="title">常見問題</b>
          </div>
        </div>
        {!data && <Loader text="處理中，請稍後" />}
        {
          !!data && data.map((item, index) => {
            return (
              <section key={`qa-section-${index}`}
                className={index === 0 ? "category first-category" : "category"}
              >
                <div className="container">
                  <small className="title">{item.title}</small>
                </div>
                {
                  item.content.map((value, index2) => {
                    return (
                      <ExpansionPanel key={`qa-section-${index}-${index2}`} className="expansion-panel">
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className="expansion-panel-summary">
                          <div className="container">
                            <Typography className={classes.heading}>{value.question}</Typography>
                          </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className="expansion-panel-detail">
                          <div className="container">
                            <Typography>{value.answer}</Typography>
                          </div>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    )
                  })
                }
              </section>
            )
          })
        }
      </div >
    )
  }
}

QA.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(QA);
