import React from 'react'

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { LINE_APP_URL } from '../../config/environment'

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});


class CouponErrorPage extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <Typography variant="h5" component="h3" className="mcip-logo"
          style={{ padding: 0, display: 'flex', justifyContent: 'start' }}
        >
          <div className="icon"></div>
          <h4>
            <span style={{ animationDelay: `.65s` }}>樂</span>
            <span style={{ animationDelay: `.73s` }}>台</span>
            <span style={{ animationDelay: `.81s` }}>計</span>
            <span style={{ animationDelay: `.89s` }}>畫</span>
          </h4>
        </Typography>
        <Typography component="p" style={{ marginTop: '.75rem' }}>
          您必須使用樂台計畫 LINE App 的 QR Code 掃描器進行掃描。
        </Typography>
        <Button color="primary" style={{ marginTop: '.75rem' }} href={LINE_APP_URL}>
          前往 LINE App >
        </Button>
      </div>
    )
  }
}

CouponErrorPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CouponErrorPage);