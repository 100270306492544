import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'
import { Route, Switch } from 'react-router-dom'
import { withRouter } from 'react-router'

import FormHomeContainer from './container/Form/HomeContainer'
import FormCheckoutContainer from './container/Form/FormCheckoutContainer'
import FormParticipateContainer from './container/Form/FormCreateContainer'
import FormUpdateContainer from './container/Form/FormUpdateContainer'

import SchoolContainer from './container/School/SchoolContainer'
import QAContainer from './container/School/QAContainer'
import ManualContainer from './container/School/ManualContainer'
import LotteryContainer from './container/School/LotteryContainer'
import CompetitionContainer from './container/Competition/CompetitionContainer'
import PopularContainer from './container/School/PopularContainer'
import VoteContainer from './container/School/VoteContainer'

import SettingContainer from './container/Setting/SettingContainer'
import ProfileContainer from './container/Setting/ProfileContainer'
import MCIPQAContainer from './container/Setting/QAContainer'
import About from './component/Setting/About'
import Story from './component/Setting/Story'
import PrivacyPolicy from './component/Setting/PrivacyPolicy'
import PaymentPolicy from './component/Setting/PaymentPolicy'

import NewsListContainer from './container/News/ListContainer'
import NewsContainer from './container/News/NewsContainer'

import LineCouponRedirecPage from './container/LineCoupon/CouponContainer'
import QRCodeScannerContainer from './container/LineCoupon/ScannerContainer'

import Full from './container/Full'

import Footer from "./component/Footer"
import lineAuth from './utils/function/lineAuth'
import { Loader } from './utils/component/component'
import { LINE_COUPON_URL, QR_CODE_SCANNER_URL } from './config/environment'

import { getSchoolsInfo } from './action/school'
import { getUserInfo } from './action/member'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lineAuth: null
    }
  }

  componentWillMount() {
    const location = this.props.location
    if (location.pathname !== LINE_COUPON_URL && location.pathname !== QR_CODE_SCANNER_URL) {
      this.setState({ lineAuth: new lineAuth(this.props.cookies, this.props.location, this.props.getUserInfo) })
    }
  }

  componentDidMount() {
    this.props.history.listen((location, action) => {
      window.scrollTo(0, 0)
    })
    this.props.getSchoolsInfo()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lineId !== this.props.lineId) {
      this.props.getSchoolsInfo()
    }
  }

  render() {
    if (this.props.location.pathname === LINE_COUPON_URL) {
      return <LineCouponRedirecPage location={this.props.location} />
    }

    if (this.props.location.pathname === QR_CODE_SCANNER_URL) {
      return <QRCodeScannerContainer location={this.props.location} />
    }

    if (!this.props.userInfo || !this.props.schoolsInfo) {
      return <Loader text="處理中，請稍後" />
    }

    return (
      <div className="notosanstc300">
        {
          this.props.userInfo &&
          <Switch>
            <Route exact path="/form/:schoolid/participate" name="FormParticipate" component={FormParticipateContainer} />
            <Route exact path="/form/:schoolid/update" name="FormUpdate" component={FormUpdateContainer} />
            <Route exact path="/form/:schoolid/checkout" name="FormMsg" component={FormCheckoutContainer} />

            <Route exact path="/school" name="school" component={FormHomeContainer} />
            <Route exact path="/school/:schoolid" name="TargetSchool" component={SchoolContainer} />
            <Route exact path="/school/:schoolid/QA" name="QA" component={QAContainer} />
            <Route exact path="/school/:schoolid/manual" name="Manual" component={ManualContainer} />
            <Route exact path="/school/:schoolid/lottery" name="Manual" component={LotteryContainer} />
            <Route exact path="/school/:schoolid/popular" name="Popular" component={PopularContainer} />
            <Route exact path="/school/:schoolid/vote" name="Vote" component={VoteContainer} />

            <Route exact path="/competition" name="Competition" component={CompetitionContainer} />

            <Route exact path="/setting" name="Setting" component={SettingContainer} />
            <Route exact path="/setting/profile" name="Profile" component={ProfileContainer} />
            <Route exact path="/setting/QA" name="MCIPQA" component={MCIPQAContainer} />
            <Route exact path="/setting/about" name="About" component={About} />
            <Route exact path="/setting/about/story" name="About" component={Story} />
            <Route exact path="/setting/about/privacy-policy" name="PrivacyPolicy" component={PrivacyPolicy} />
            <Route exact path="/setting/about/payment-policy" name="PaymentPolicy" component={PaymentPolicy} />

            <Route exact path="/news" name="NewsList" component={NewsListContainer} />
            <Route exact path="/news/:newsId" name="News" component={NewsContainer} />

            <Route path="/" name="Full" component={Full} />
          </Switch>
        }
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  schoolsInfo: state.school.schoolsInfo,
  userInfo: state.member.info,
  lineId: state.member.info ? state.member.info.id : null,
})

const mapDispatchToProps = dispatch => ({
  getSchoolsInfo: () =>
    dispatch(getSchoolsInfo()),
  getUserInfo: lineId =>
    dispatch(getUserInfo(lineId)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withCookies(App)))
