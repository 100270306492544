import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { setTargetSchool } from '../../action/school'
import { redirectToFBFanpage } from '../../utils/function/functions'
import { Loader } from '../../utils/component/component'
import School from '../../component/School/School'

import agent from '../../agent/agent'

const isEmpty = require('lodash.isempty')

class SchoolContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isWaiting: true,

      isRedirectToParticipate: false,
      isRedirectToHome: false,
      isRedirectToQA: false,
      isRedirectToManual: false,
      isRedirectToLottery: false,
      isRedirectToPopular: false,
      isRedirectToProfile: false,

      isPopularBtnEnabled: false,
      isQABtnEnabled: false,
      isManualBtnEnabled: false,
      isLotteryBtnEnabled: false,

      targetSchoolInfo: null,
      lotteryInfo: null,
    }
    this.handleRedirect = this.handleRedirect.bind(this)
    this.redirectToFB = this.redirectToFB.bind(this)
    this.setBtnState = this.setBtnState.bind(this)
    this.getLotteryInfo = this.getLotteryInfo.bind(this)
  }

  componentWillMount() {
    if (this.props.schoolsInfo) {
      const router = this.props.location
      const pathname = router.pathname.split('/')
      const targetSchoolId = pathname[2]
      if (targetSchoolId) {
        const targetSchoolInfo = this.props.schoolsInfo.filter(school => school.id === targetSchoolId)[0]
        const period = targetSchoolInfo.period
        this.props.setTargetSchool(targetSchoolInfo)
        this.setState({ targetSchoolInfo }, () => {
          this.setBtnState()
          this.getLotteryInfo(targetSchoolId, period)
        })
      } else {
        this.setState({ isRedirectToHome: true })
      }
    } else {
      this.setState({ isRedirectToHome: true })
    }
  }

  handleRedirect(pathParam) {
    setTimeout(() => { this.setState({ [pathParam]: true }) }, 100)
  }

  redirectToFB() {
    redirectToFBFanpage(this.state.targetSchoolInfo.fbLink)
  }

  setBtnState() {
    this.setState({
      isPopularBtnEnabled: this.state.targetSchoolInfo.popularVote.isEnabled,
      isQABtnEnabled: !isEmpty(this.state.targetSchoolInfo.QA),
      isManualBtnEnabled: !isEmpty(this.state.targetSchoolInfo.manual),
    })
  }

  async getLotteryInfo(targetSchoolId, period) {
    if (this.props.schoolsInfo) {
      try {
        const result = await agent.School.getLotteryInfo(targetSchoolId, period)
        const resultJson = await result.json()
        this.setState({ lotteryInfo: resultJson, isLotteryBtnEnabled: !isEmpty(resultJson) })
      } catch (err) {
        this.setState({ lotteryInfo: [], isLotteryBtnEnabled: false })
      }
    }
    this.setState({ isWaiting: false })
  }

  render() {
    if (this.state.isRedirectToParticipate) {
      return <Redirect push to={`/form/${this.state.targetSchoolInfo.id}/participate`} />
    }

    if (this.state.isRedirectToHome) {
      return <Redirect push to="/school" />
    }

    if (this.state.isRedirectToManual) {
      return <Redirect push to={`/school/${this.state.targetSchoolInfo.id}/manual`} />
    }

    if (this.state.isRedirectToQA) {
      return <Redirect push to={`/school/${this.state.targetSchoolInfo.id}/QA`} />
    }

    if (this.state.isRedirectToLottery) {
      return <Redirect push to={`/school/${this.state.targetSchoolInfo.id}/lottery`} />
    }

    if (this.state.isRedirectToPopular) {
      return <Redirect push to={`/school/${this.state.targetSchoolInfo.id}/popular`} />
    }

    if (this.state.isRedirectToProfile) {
      return <Redirect push to="/setting/profile" />
    }

    if (this.state.isWaiting) {
      return <Loader text="處理中，請稍後" />
    }
    return (
      <School
        targetSchoolInfo={this.state.targetSchoolInfo}
        lotteryInfo={this.state.lotteryInfo}
        userinfo={this.props.userinfo}

        isPopularBtnEnabled={this.state.isPopularBtnEnabled}
        isQABtnEnabled={this.state.isQABtnEnabled}
        isManualBtnEnabled={this.state.isManualBtnEnabled}
        isLotteryBtnEnabled={this.state.isLotteryBtnEnabled}

        handleRedirect={this.handleRedirect}
        handleTargetSchoolChange={this.handleTargetSchoolChange}
        redirectToFB={this.redirectToFB}
      />
    )
  }
}

const mapStateToProps = state => ({
  schoolsInfo: state.school.schoolsInfo,
  userinfo: state.member.info,
})

const mapDispatchToProps = dispatch => ({
  setTargetSchool: school => {
    dispatch(setTargetSchool(school))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SchoolContainer)
