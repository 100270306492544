import React from 'react'
import { connect } from 'react-redux'

import {
  getComment,
} from '../../action/comment'

import Comment from '../../component/Setting/Comment'

const isEmpty = require('lodash.isempty')

class CommentContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      comments: null,

      targetCommentImage: null,
    }
    this.setComment = this.setComment.bind(this)

    this.handleClickImage = this.handleClickImage.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

  componentWillMount() {
    if (this.props.lineId && !this.props.comments) {
      this.props.getComment(this.props.lineId)
    } else {
      this.setComment()
    }
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.lineId !== this.props.lineId) && this.props.lineId)
      this.props.getComment(this.props.lineId)

    if (prevProps.comments !== this.props.comments)
      this.setComment()
  }

  setComment() {
    if (isEmpty(this.props.comments)) {
      this.setState({ comments: [] })
    } else {
      const filterComments = this.props.comments.filter(comment => comment.comment)
      if (filterComments)
        this.setState({ comments: filterComments })
      else
        this.setState({ comments: [] })
    }
  }

  handleClickImage(imgSrc) {
    this.setState({ targetCommentImage: imgSrc })
  }

  handleCloseModal() {
    this.setState({ targetCommentImage: null })
  }

  render() {
    return (
      <Comment
        comments={this.state.comments}
        schoolsInfo={this.props.schoolsInfo}
        targetCommentImage={this.state.targetCommentImage}

        handleClickImage={this.handleClickImage}
        handleCloseModal={this.handleCloseModal}
      />
    )
  }
}

const mapStateToProps = state => ({
  comments: state.comment.comments,
  lineId: state.member.info == null ? null : state.member.info.id,
  userInfo: state.member.info,
  schoolsInfo: state.school.schoolsInfo,
})

const mapDispatchToProps = dispatch => ({
  getComment: lineId => {
    dispatch(getComment(lineId))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(CommentContainer)