import agent from './agent'

export const Article = {
  get: async (afterArticleId, limit) => {
    const articleId = afterArticleId ? afterArticleId : ''
    return new Promise((resolve, reject) => {
      return agent.Request.get(`/article/app?after=${articleId}&limit=${limit}`)
        .then(response => resolve(response))
    })
  },
}