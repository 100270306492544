import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import ReactScroll from 'react-scroll'

import {
	getCompetition,
	setCompetition,
} from '../../action/competition'

import Competition from '../../component/Competition/Competition'
import { COMPETITION_STATUS } from '../../config/environment'

import dayjs from 'dayjs'
import 'dayjs/locale/zh-tw' // load on demand

const ReactScroller = ReactScroll.scroller

class CompetitionContainer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			targetSchool: '',
			targetSchoolName: '',
			competition: null,

			groupIndexes: [],
			tabListIndex: 0,
			playingGroupIndex: 0,
			scrollElementName: null,	// 欲滑動至的歌

			fromUpdateTime: null,
			isScrolledHeaderShow: false,
		}
		this.setTargetSchool = this.setTargetSchool.bind(this)
		this.handleGroupIndexChange = this.handleGroupIndexChange.bind(this)

		this.setGroupIndexAndSliderIndex = this.setGroupIndexAndSliderIndex.bind(this)
		this.setTabListIndex = this.setTabListIndex.bind(this)
		this.scrollToPlayingSong = this.scrollToPlayingSong.bind(this)
	}

	componentWillUnmount() {
		window.onscroll = null
	}

	componentDidMount() {
		window.onscroll = () => {
			const threshold = 100
			const top = window.pageYOffset || document.documentElement.scrollTop
			this.setState({ isScrolledHeaderShow: top > threshold })
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			((prevProps.competition !== this.props.competition) || (prevProps.schoolsInfo !== this.props.schoolsInfo)) &&
			this.props.schoolsInfo &&
			this.props.competition
		) {

			/** 過濾進行中賽事 **/
			const filterCompetition = this.props.competition.filter(item => {
				if (item.isPlaying) {
					const filterSchool = this.props.schoolsInfo.filter(school => {
						return school.id === item.id &&
							((school.isOnCampus && this.props.userInfo.school.name === school.schoolName) || !school.isOnCampus)
					})
					return filterSchool.length !== 0
				}
				else return false
			})

			this.setState({ competition: filterCompetition })
			this.props.setCompetition(filterCompetition)	// 將即時賽況連動 reducer state

			/** 若已選擇學校賽事的情況 **/
			if (this.state.targetSchool !== '') {
				const targetSchool = this.props.competition.find(item => item.id === this.state.targetSchool.id)
				this.setState({
					targetSchool: targetSchool,
					fromUpdateTime: dayjs(targetSchool.timestamp).locale('zh-tw').from(+new Date())
				})
			}
			/** 還沒選擇 **/
			else {
				/** 若只有一間正在比，則直接選擇 **/
				if (filterCompetition.length === 1) {
					this.setGroupIndexAndSliderIndex(filterCompetition[0])
					this.setState({
						targetSchool: filterCompetition[0],
						fromUpdateTime: dayjs(filterCompetition[0].timestamp).locale('zh-tw').from(+new Date())
					})
				}
			}
		}

		/** 若進行中的歌曲更換 則直接滑動至該首歌 **/
		if (prevState.scrollElementName !== this.state.scrollElementName) {
			this.scrollToPlayingSong()
		}

		this.handleGroupIndexChange(prevProps, prevState)
	}

	handleGroupIndexChange(prevProps, prevState) {
		/** Set target school name **/
		if (
			((prevState.targetSchool !== this.state.targetSchool) || (prevProps.schoolsInfo !== this.props.schoolsInfo)) &&
			this.props.schoolsInfo &&
			this.state.targetSchool !== ''
		) {
			const targetSchool = this.props.schoolsInfo.find(school => school.id === this.state.targetSchool.id)
			const targetCompetition = this.props.competition.find(competition => competition.id === targetSchool.id)
			this.setGroupIndexAndSliderIndex(targetCompetition)
			this.setState({ targetSchoolName: targetSchool.name })
		}
	}

	setTargetSchool(schoolId) {
		setTimeout(() => {
			const targetSchool = this.state.competition.find(item => item.id === schoolId)
			this.setGroupIndexAndSliderIndex(targetSchool)
			this.setState({ targetSchool: targetSchool })
		}, 100)
	}

	setGroupIndexAndSliderIndex(targetCompetition) {
		let groupIndexes = []
		let playingGroupIndex = 0
		let groupIndex = 0
		let scrollElementName = ""

		for (const key in targetCompetition) {
			if (!isNaN(key)) {
				let songIndexByGroup = 0
				groupIndexes.push(key)
				for (const info of targetCompetition[key]) {
					if (info.status === COMPETITION_STATUS.PLAYING) {
						playingGroupIndex = groupIndex
						scrollElementName = String(key) + String(songIndexByGroup)
					}
					songIndexByGroup++
				}
			}
			groupIndex++
		}

		this.setState({
			groupIndexes: groupIndexes,
			tabListIndex: playingGroupIndex,
			playingGroupIndex: playingGroupIndex,
			scrollElementName: scrollElementName,
		})
	}

	setTabListIndex(index) {
		if (index !== this.state.playingGroupIndex) {
			ReactScroll.animateScroll.scrollToTop()
		} else {
			setTimeout(() => { this.scrollToPlayingSong() }, 100)
		}
		this.setState({ tabListIndex: index })
	}

	scrollToPlayingSong() {
		ReactScroller.scrollTo(this.state.scrollElementName, {
			smooth: true,
			offset: -200,
		})
	}

	render() {
		return (
			<Competition
				competition={this.state.competition}

				schoolsInfo={this.props.schoolsInfo}
				targetSchool={this.state.targetSchool}
				targetSchoolName={this.state.targetSchoolName}
				setTargetSchool={this.setTargetSchool}
				isScrolledHeaderShow={this.state.isScrolledHeaderShow}

				tabListIndex={this.state.tabListIndex}
				playingGroupIndex={this.state.playingGroupIndex}
				groupIndexes={this.state.groupIndexes}
				scrollElementName={this.state.scrollElementName}
				setTabListIndex={this.setTabListIndex}

				fromUpdateTime={this.state.fromUpdateTime}
			/>
		)
	}
}

const mapStateToProps = state => ({
	schoolsInfo: state.school.schoolsInfo,
	userInfo: state.member.info,
})

const mapDispatchToProps = dispatch => ({
	getCompetition: () => {
		dispatch(getCompetition())
	},
	setCompetition: competition => {
		dispatch(setCompetition(competition))
	}
})

// export default connect(mapStateToProps, mapDispatchToProps)(CompetitionContainer)

export default compose(
	firestoreConnect([
		'competition' // { path: '/route...' }
	]),
	connect((state) => {
		return {
			competition: state.firestore.ordered.competition
		}
	})
)(connect(mapStateToProps, mapDispatchToProps)(CompetitionContainer))
