import React from 'react'
import { Link } from 'react-router-dom'
import { Loader } from '../../utils/component/component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { ChevronRight, Info } from '@material-ui/icons'
import { FileDocument, CommentText, Account, HelpCircle } from 'mdi-material-ui'

import '../../utils/css/setting/_setting.sass'

export default class Setting extends React.Component {
  render() {
    return (
      <div className="setting">
        <div className="container">
          <div className="update-success-remind animated fadeInDown"
            style={{ display: this.props.isShowUpdateSuccessRemind === true ? "block" : "none", animationDuration: `.5s` }}
          >
            <FontAwesomeIcon icon="check-circle" className="mr-2" />
            更新成功
          </div>
          {this.props.isWaiting && <Loader text="處理中，請稍後" />}
        </div>
        {
          !this.props.isWaiting &&
          <React.Fragment>
            <List dense={false} className="list">
              <div className="container">
                <ListItem button component={Link} to="/setting/profile">
                  <ListItemAvatar>
                    <Avatar>
                      <Account />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={this.props.userInfo.name} secondary={this.props.userInfo.school.name} />
                  <ChevronRight />
                </ListItem>
              </div>
            </List>
            <List disablePadding className="list">
              <div className="container">
                <ListItem button component={Link} to="/formlist">
                  <ListItemIcon>
                    <FileDocument />
                  </ListItemIcon>
                  <ListItemText primary="報名資訊" />
                  <ChevronRight />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem button component={Link} to="/setting/comment">
                  <ListItemIcon>
                    <CommentText />
                  </ListItemIcon>
                  <ListItemText primary="查看評語" />
                  <ChevronRight />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem button component={Link} to="/setting/QA">
                  <ListItemIcon>
                    <HelpCircle />
                  </ListItemIcon>
                  <ListItemText primary="常見問題" />
                  <ChevronRight />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem button component={Link} to="/setting/about">
                  <ListItemIcon>
                    <Info />
                  </ListItemIcon>
                  <ListItemText primary="關於" />
                  <ChevronRight />
                </ListItem>
              </div>
            </List>
          </React.Fragment>
        }
      </div>
    )
  }
}