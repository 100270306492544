import React from 'react'

const PrivacyPolicy = props => {
  return (
    <div className="privacy-policy">
      <div className="intro">
        <p>【樂台計畫金流服務】(以下稱本服務)，是利用系統自動化代收報名費用方式，協助合作院校降低人工對帳錯誤發生率與時間成本，同時向參賽者推播繳費完成訊息與更新報名表單繳費狀態。</p>
        <p>當您參與院校賽事擁有「樂台計畫金流服務」合作關係時，您必須閱讀、暸解並同意本服務所有約定內容及其修改或變更。</p>
      </div>
      <div className="content">
        <div className="group">
          <h6 className="title">一、服務範圍</h6>
          <ol>
            <li>本服務目前僅適用於金流合作之院校</li>
            <li>本服務目前僅提供院校賽事報名繳費用途。</li>
            <li>本服務目前僅提供 ATM 虛擬帳戶方式匯款。</li>
          </ol>
        </div>
        <div className="group">
          <h6 className="title">二、關於本服務於院校賽事之使用</h6>
          <ol>
            <li>當您第一次使用本服務前，必須至【我】個人資訊處更新退款帳戶資訊，以利系統處理退款相關事宜。</li>
            <li>有效繳費期限為 3 天，逾期則視作未報名，參賽者須重新填寫該院校報名表單。</li>
            <li>參賽者繳費完畢後會於「一個工作天內」收到 Line 推播繳費完成訊息以及系統表單繳費狀態更新。</li>
            <li>若參賽者於該院校賽事「報名截止」前向系統申請棄賽，系統將會退還院校賽事報名費用之 50%，反之則不予退費。</li>
            <li>退費時間為該院校賽事「報名截止」後 3~14 個工作天內，系統自動將款項退入參賽者所填寫退款帳戶號碼。</li>
            <li>承第 5 項，「淡江金韶獎」與「流音之星」因賽制規章規定，系統將不予退還任何報名費用。</li>
            <li>承第 5 項，「高科大弦音盃」因賽制規章規定，報名截止後，系統將不予退還任何報名費用。</li>
            <li>承第 5 項，「政大金旋獎」因賽制規章規定，有關退費與棄賽之條約內容（4、5點）由政大金旋獎自行處理，系統不需作出任何保證或承擔任何責任。</li>
            <li>系統依據會員資訊「退換帳戶號碼」進行退款作業，如因資訊填寫錯誤造成退款失敗，樂台計畫無須承擔任何責任。</li>
            <li>非金流合作院校相關繳費問題，樂台計畫不會作出任何保證或承擔任何責任。</li>
          </ol>
        </div>
        <div className="group">
          <h6 className="title">三、錯誤付款之處理</h6>
          <ol>
            <li>如錯誤付款係因可歸責於本系統之原因所生，樂台計畫有權更正其發現之錯誤付款，並應於知悉上開事實時儘速通知使用者。使用者應取消原經許可之錯誤交易金額之付款，並以使用者之退款帳戶資訊退還正確交易金額。</li>
            <li>如錯誤付款係因可歸責於金流合作夥伴之原因所生，例如金流合作院校輸入錯誤之交易金額或輸入錯誤之受款人，使用者應立即通知樂台計畫，俾樂台計畫協助處理，但使用者不得就此錯誤付款向樂台計畫為任何追索。惟使用者得聯繫使用本服務給付款項之金流合作夥伴，並視情況請求金流合作夥伴退款。樂台計畫不會代墊該退款予使用者，亦不會回復使用者錯誤給付之款項。又因不可歸責於使用者之事由而發生支付錯誤時，樂台計畫應協助使用者更正錯誤，並提供使用者其他必要協助。</li>
          </ol>
        </div>
        <div className="group">
          <h6 className="title">四、資訊安全</h6>
          <ol>
            <li>樂台計畫將採取適當措施以確保本服務資訊系統之資訊安全。惟使用者亦應確保其資訊系統之安全並防止第三人非法入侵、取得、竄改、或損害個人資訊及相關紀錄。</li>
            <li>如有任何第三人破解樂台計畫資訊系統之保護措施或利用資訊系統之漏洞相關爭議，由本公司就該事實不存在負舉證責任。</li>
            <li>就第三人入侵本公司資訊系統對使用者所造成之損害，應由樂台計畫負賠償責任，但該入侵可歸責於使用者時，不在此限。</li>
          </ol>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy