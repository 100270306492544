import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import IconButton from '@material-ui/core/IconButton'
import Chip from '@material-ui/core/Chip'
import { ChevronLeft } from '@material-ui/icons'
import { Account, AccountGroup, GuitarAcoustic, MicrophoneVariant, LightbulbOn } from 'mdi-material-ui'

import { Loader } from '../../utils/component/component'
import { GROUP, GROUP_ICON } from '../../config/environment'

const isEmpty = require('lodash.isempty')

const styles = theme => ({
	chip: {
		margin: `6px 0`,
	},
});


const GroupIcon = props => {
	const icon = props.icon
	switch (icon) {
		case "user":
			return <Account />
		case "users":
			return <AccountGroup />
		case "guitar":
			return <GuitarAcoustic />
		case "microphone":
			return <MicrophoneVariant />
		case "idea":
			return <LightbulbOn />
		default:
			return <Account />
	}
}

const EmptyFormList = props => {
	return (
		<div>
			<IconButton component={Link} to="/setting" className="icon" >
				<ChevronLeft />
			</IconButton>
			<div className="empty-message">
				<img alt="empty" className="icon" src={require('../../utils/icons/empty-box.svg')} />
				<span>目前尚無報名資訊</span>
			</div>
		</div>
	)
}

const FormLists = props => {
	return (
		<div style={{ marginBottom: "5rem" }}>
			<div className="top-bar">
				<IconButton component={Link} to="/setting" className="icon" >
					<ChevronLeft />
				</IconButton>
				<b className="title">報名資訊</b>
			</div>
			<List className="list" dense disablePadding>
				{
					props.formList.map((item, index) => {
						const school = props.schoolsInfo.find(schoolInfo => schoolInfo.id === item.organizer)
						const schoolName = school ? school.name : ''
						const chipLabel = props.getFormLevelName(item)
						return (
							<div key={item.id}>
								<ListItem button onClick={() => props.handleClickForm(item)}
									style={{ opacity: (props.isCloseEdit(item, school) || item.status === 3) ? .6 : 1 }}
								>
									<ListItemIcon>
										<GroupIcon icon={GROUP_ICON[item.group]} />
									</ListItemIcon>
									<ListItemText primary={`${schoolName}`}
										secondary={`${GROUP[item.group]} - ${item.song}`}
									/>
									{
										(!props.isCloseEdit(item, school) && item.status !== 3) &&
										<Chip label={chipLabel}
											className={props.classes.chip} variant="outlined"
										/>
									}
								</ListItem>
								{index !== props.formList.length - 1 && <Divider variant="inset" />}
							</div>
						)
					})
				}
			</List>
		</div>
	)
}

class FormList extends React.Component {

	render() {
		const { classes } = this.props

		return (
			<div className="container">
				<div className="row">
					<div className="col-12">
						{
							!this.props.formList &&
							<Loader text="處理中，請稍後" />
						}
						{
							this.props.formList &&
							this.props.formList.length === 0 &&
							<EmptyFormList />
						}
						{
							!isEmpty(this.props.formList) &&
							<FormLists
								formList={this.props.formList}
								schoolsInfo={this.props.schoolsInfo}
								classes={classes}

								toggleModal={this.props.toggleModal}
								handleClickForm={this.props.handleClickForm}
								getFormLevelName={this.props.getFormLevelName}
								getDeadline={this.props.getDeadline}
								isCloseEdit={this.props.isCloseEdit}
							/>
						}
					</div>
				</div>
			</div>
		)
	}
}

FormList.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FormList);