import agent from '../agent/agent'

export const RECEIVE_USER_INFO = "RECEIVE_USER_INFO"
export const CLOSE_SNACKBAR_FLAG = "CLOSE_SNACKBAR_FLAG"

export const getUserInfo = lineId => dispatch => {
	return agent.Setting.getUserInfo(lineId)
		.then(result => result.json())
		.then(resultJson => { dispatch(receiveUserInfo(resultJson)) })
		.catch(error => { console.error(error) })
}

export const receiveUserInfo = info => {
	return {
		type: RECEIVE_USER_INFO,
		info
	}
}