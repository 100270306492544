import {
  RECEIVE_BANK_CODES,
} from '../action/bankCode'

const initialState = {
  bankCodes: null,
}

export default(state = initialState, action) => {
  switch(action.type){
    case RECEIVE_BANK_CODES:
      return {
        ...state,
        bankCodes: action.bankCodes
      }
    default:
      return state
  }
}