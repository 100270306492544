import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { isSubArray } from '../../utils/function/functions'

import Home from '../../component/Form/Home'
import tag from '../../config/tag'

class HomeContainer extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      schoolsInfo: null,
      targetSchoolId: null,
      isRedirectToSetting: false,
      tags: [],
    }
    this.setSchoolsInfo = this.setSchoolsInfo.bind(this)
    this.setTargetSchool = this.setTargetSchool.bind(this)
    this.setTags = this.setTags.bind(this)
    this.filterSchoolByTag = this.filterSchoolByTag.bind(this)
  }

  componentWillMount(){
    this.setSchoolsInfo()
  }

  componentDidUpdate(prevProps){
    if(prevProps !== this.props)
      this.setSchoolsInfo()
  }

  setSchoolsInfo(){
    if(this.props.schoolsInfo && this.props.userInfo){
      const filterSchools = this.props.schoolsInfo.filter(school =>
        (
          (!school.isOnCampus || (school.isOnCampus && school.schoolName === this.props.userInfo.school.name)) &&
          school.id !== "mcip"
        )
      )
      this.setState({ schoolsInfo: filterSchools })
    }
  }

  setTargetSchool(targetSchoolId){
    this.setState({ targetSchoolId })
  }

  setTags(tag){
    if(this.state.tags.includes(tag)){
      this.setState({ tags: this.state.tags.filter(item => item !== tag) }, () => {
        this.filterSchoolByTag()
      })
    }else{
      this.setState({ tags: [...this.state.tags, tag] }, () => {
        this.filterSchoolByTag()
      })
    }
  }

  filterSchoolByTag(){
    if(this.props.schoolsInfo){
      const targetSchools = []
      for(const school of this.props.schoolsInfo){
        if(school.id === "ForTest" || isSubArray(school.tags, this.state.tags)){
          targetSchools.push(school)
        }
      }
      this.setState({ schoolsInfo: targetSchools })
    }
  }

  render(){
    if(this.state.targetSchoolId){
      return <Redirect push to={`/school/${this.state.targetSchoolId}`} />
    }

    if(this.state.isRedirectToSetting) {
      return <Redirect push to="/setting/profile?source=form" />
    }

    return (
      <Home
        schoolsInfo={this.state.schoolsInfo}
        setTargetSchool={this.setTargetSchool}

        tags={this.state.tags}
        schoolTags={tag.SCHOOL}
        setTags={this.setTags}
      />
    )
  }
}

const mapStateToProps = state => ({
  userInfo: state.member.info,
  schoolsInfo: state.school.schoolsInfo,
})

const mapDispatchToProps = dispatch => ({ })

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer)