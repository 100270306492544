import React from 'react'
import { Link } from 'react-router-dom'
import { Loader } from '../../utils/component/component'
import { GROUP } from '../../config/environment'

import IconButton from '@material-ui/core/IconButton';
import { ChevronLeft, Close } from '@material-ui/icons'
import Dialog from '@material-ui/core/Dialog';

import '../../utils/css/setting/_comment.sass'

const isEmpty = require("lodash.isempty")

const OneImage = props => {
  const { handleClickImage, imgBase64 } = props
  return (
    <div className="row no-gutters img-wrapper">
      <div className="col-12 col-md-6">
        <div className="img">
          <img alt="comment" src={imgBase64} onClick={() => handleClickImage(imgBase64)} />
        </div>
      </div>
    </div>
  )
}

const MultiImage = props => {
  const { handleClickImage, imgBase64 } = props
  let grid = []
  switch (imgBase64.length) {
    case 2:
      grid = ["col-12 col-md-6", "col-12 col-md-6"]; break;
    case 3:
    default:
      grid = ["col-12 col-md-6", "col-6", "col-6"]; break;

  }
  return (
    <div className="row no-gutters img-wrapper">
      {
        imgBase64.map((img, index) => {
          return (
            <div className={grid[index]} key={img}>
              <div className="img">
                <img alt="comment" src={img} onClick={() => handleClickImage(img)} />
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default class Comment extends React.Component {
  render() {
    const {
      comments, schoolsInfo, targetCommentImage,
      handleCloseModal, handleClickImage,
    } = this.props
    return (
      <div className="container">
        {
          (comments === null || !schoolsInfo) &&
          <Loader text="處理中，請稍後" />
        }
        {
          schoolsInfo &&
          comments &&
          comments.length === 0 &&
          <React.Fragment>
            <IconButton component={Link} to="/setting" className="icon" >
              <ChevronLeft />
            </IconButton>
            <div className="empty-message">
              <img className="icon" alt="empty" src={require('../../utils/icons/empty-box.svg')} />
              <span>尚無任何相關評語</span>
            </div>
          </React.Fragment>
        }
        {
          schoolsInfo &&
          !isEmpty(comments) &&
          <div className="page">
            <div className="top-bar">
              <IconButton component={Link} to="/setting" className="icon" >
                <ChevronLeft />
              </IconButton>
              <b className="title">評語</b>
            </div>
            {
              comments.map((item, index) => {
                const school = schoolsInfo.find(school => school.id === item.organizer)
                const { name, period } = school
                const { score, content, imgBase64 } = item.comment
                return (
                  <div className="comment-item" key={item.id}>
                    <h6 className="song">
                      {item.song}
                    </h6>
                    <div className="school">
                      {name}
                      <small>第 {period} 屆 | {GROUP[item.group]}</small>
                    </div>
                    {
                      (score || content) &&
                      <div className="comment">
                        {
                          score &&
                          <div className="score">
                            {item.comment.score}
                            <span className="label"> 分 </span>
                          </div>
                        }
                        {content && <p className="content">{content}</p>}
                      </div>
                    }
                    {
                      imgBase64 && typeof imgBase64 === "string" &&
                      <OneImage imgBase64={imgBase64} handleClickImage={handleClickImage} />
                    }
                    {
                      imgBase64 && Array.isArray(imgBase64) && imgBase64.length === 1 &&
                      <OneImage imgBase64={imgBase64[0]} handleClickImage={handleClickImage} />
                    }
                    {
                      imgBase64 && Array.isArray(imgBase64) && imgBase64.length > 1 &&
                      <MultiImage imgBase64={imgBase64} handleClickImage={handleClickImage} />
                    }
                  </div>
                )
              })
            }
          </div >
        }
        <Dialog onClose={handleCloseModal} open={!!targetCommentImage} fullScreen
          className="comment-image-modal"
        >
          <div className="container">
            <div className="icon-wrapper">
              <IconButton color="inherit" onClick={handleCloseModal} aria-label="Close">
                <Close />
              </IconButton>
            </div>
            {
              targetCommentImage &&
              <img alt="comment" src={targetCommentImage} className="image" />
            }
          </div>
        </Dialog>
      </div>
    )
  }
}
