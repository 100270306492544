import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import member from './reducer/member'
import form from './reducer/form'
import school from './reducer/school'
import comment from './reducer/comment'
import competition from './reducer/competition'
import bankCode from './reducer/bankCode'
import article from './reducer/article'
import qa from './reducer/qa'

export default combineReducers({
	member,
	form,
	school,
	comment,
	competition,
	bankCode,
	article,
	firestore: firestoreReducer, // <- needed if using firestore
	qa,
});
