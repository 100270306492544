import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Vote from '../../component/School/Vote'
import agent from '../../agent/agent'
import { getPopularFormList } from '../../action/form'
import { isMember } from '../../utils/function/functions'

class VoteContainer extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			maxValue: null,
			targetId: null,		// 欲投票 ID
			finalId: null,		// 最終決定 ID
			popularFormList: null,
			stateMsg: null,
			isWaiting: false,
			isOpen: false,
      isRedirectToMenu: false,
      isRedirectToPopular: false,
		}
		this.setTarget = this.setTarget.bind(this)
		this.setMaxValue = this.setMaxValue.bind(this)
		this.vote = this.vote.bind(this)
		this.initTargetId = this.initTargetId.bind(this)
		this.isVoteBtnDisabled = this.isVoteBtnDisabled.bind(this)
		this.orderFormListByVote = this.orderFormListByVote.bind(this)
		this.setStateMsg = this.setStateMsg.bind(this)
    this.handleRedirect = this.handleRedirect.bind(this)
	}

	componentWillMount(){
		if(this.props.targetSchoolInfo){
			this.initTargetId()
			this.setMaxValue()
			this.orderFormListByVote()
			this.setStateMsg()
		}
	}

	componentDidUpdate(prevProps){
		if(prevProps.popularFormList !== this.props.popularFormList){
			if(this.state.isWaiting){
				this.setState({ isWaiting: false })
			}
			this.orderFormListByVote()
			this.setMaxValue()
			this.setStateMsg()
		}
	}

	setTarget(id){
		if(this.state.isOpen)
			this.setState({ targetId: id === this.state.targetId ? null : id })
	}

	setMaxValue(){
		this.setState({ maxValue: Math.max.apply(Math, this.props.popularFormList.map(o => o.numOfVote )) })
	}

	async vote(){
		if(!isMember(this.props.userinfo)){
			this.setState({ isRedirectToSetting: true })
		}else {
			this.setState({
				isWaiting: true,
				finalId: this.state.targetId
			})
			const targetId = this.state.targetId
			const schoolId = this.props.targetSchoolInfo.id
			const period = this.props.targetSchoolInfo.period
			const body = {
				formId: targetId,
				schoolId: schoolId,
				period: period
			}
			await agent.School.vote(body)
			this.props.getPopularFormList(schoolId, period)
		}
	}

	initTargetId(){
		for(let form of this.props.popularFormList){
			if(form.isVote){
				this.setState({
					targetId: form.id,
					finalId: form.id,
				})
			}
		}
	}

	isVoteBtnDisabled(){
		if(
				this.state.isWaiting || this.state.targetId == null ||
				(this.state.targetId === this.state.finalId)
			){
			return true
		}
		return false
	}

	orderFormListByVote(){
		this.setState({ popularFormList: this.props.popularFormList.sort((a, b) => a.numOfVote < b.numOfVote ? 1 : -1 ) })
	}

	setStateMsg(){
		const currTime = +new Date()
		const beginTime = this.props.targetSchoolInfo.popularVote.begin
		const endTime = this.props.targetSchoolInfo.popularVote.end
		const isOpen = this.props.targetSchoolInfo.popularVote.isOpen
		if(currTime > beginTime && currTime < endTime && isOpen){
			this.setState({ stateMsg: "開放中", isOpen	: true })
		}else if(currTime > beginTime && currTime < endTime && !isOpen){
			this.setState({ stateMsg: "關閉中", isOpen	: false })
		}else if(currTime < beginTime){
			this.setState({ stateMsg: "尚未開放", isOpen	: false })
		}else if(currTime > endTime){
			this.setState({ stateMsg: "已結束", isOpen	: false })
		}
	}

  handleRedirect(pathParam){
    this.setState({ [pathParam]: true })
  }

	render(){
		if(this.state.isRedirectToSetting){
			return <Redirect push to="/setting/profile?source=popular" />
		}

		if(!this.props.targetSchoolInfo){
			return <Redirect push to="/school" />
		}

		if(this.state.isRedirectToPopular){
			return <Redirect push to={`/school/${this.props.targetSchoolInfo.id}/popular`} />
		}

		return (
			<Vote
				popularFormList={this.state.popularFormList}
				maxValue={this.state.maxValue}
				targetId={this.state.targetId}
				finalId={this.state.finalId}
				targetSchoolInfo={this.props.targetSchoolInfo}

				isWaiting={this.state.isWaiting}
				isOpen={this.state.isOpen}
				stateMsg={this.state.stateMsg}

				setTarget={this.setTarget}
				vote={this.vote}
				isVoteBtnDisabled={this.isVoteBtnDisabled}

				handleRedirect={this.handleRedirect}
			/>
		)
	}
}

const mapStateToProps = state => ({
	popularFormList: state.form.popularFormList,
  targetSchoolInfo: state.school.targetSchoolInfo,
	userinfo: state.member.info,
})

const mapDispatchToProps = dispatch => ({
	getPopularFormList: (schoolId, period) => {
		dispatch(getPopularFormList(schoolId, period))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(VoteContainer)