import agent from '../agent/agent'

export const RECEIVE_FORM_LIST = "RECEIVE_FORM_LIST"
export const GET_TARGET_FORM = "GET_TARGET_FORM"
export const RECEIVE_POPULAR_FORM_LIST = "RECEIVE_POPULAR_FORM_LIST"

export const getFormList = () => dispatch => {
	return agent.Form.getFormList()
		.then(result => result.json())
		.then(resultJson => dispatch(receiveFormList(resultJson)))
}

const receiveFormList = infos => {
	return {
		type: RECEIVE_FORM_LIST,
		infos
	}
}

export const getTargetForm = id =>{
	return {
		type: GET_TARGET_FORM,
		id
	}
}

export const getPopularFormList = (schoolId, period) => dispatch => {
	return agent.Form.getPopularFormList(schoolId, period)
		.then(result => result.json())
		.then(resultJson => dispatch(receivePopularFormList(resultJson)))
}

const receivePopularFormList = infos => {
	return {
		type: RECEIVE_POPULAR_FORM_LIST,
		infos
	}
}