import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import agent from '../../agent/agent'
import Lottery from '../../component/School/Lottery'

import { getLotteryInfo } from '../../action/school'
import { isMember } from '../../utils/function/functions'

const CONTENT_HEIGHT_LIMIT = 72

class LotteryContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowDetailIndex: [],

      isWaitingAttend: false,
      isSuccessAttendIndexes: [],
      isRedirectToSetting: false,
      isRedirectToMenu: false,
      isShowModal: false,         // 得獎 modal
      isShowSuccessModal: false,  // 參加成功 modal
      modalIndex: null,
    }
    this.handleRedirect = this.handleRedirect.bind(this)
    this.toggleLotteryContent = this.toggleLotteryContent.bind(this)
    this.attendLottery = this.attendLottery.bind(this)
    this.setModalIndex = this.setModalIndex.bind(this)

    this.isLotteryOpen = this.isLotteryOpen.bind(this)
    this.getLotteryStateMsg = this.getLotteryStateMsg.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.closeSuccessModal = this.closeSuccessModal.bind(this)
  }

  toggleLotteryContent(index) {
    let lotteryContentIndex = this.state.isShowDetailIndex
    lotteryContentIndex[index] = !lotteryContentIndex[index]
    this.setState({ isShowDetailIndex: lotteryContentIndex })
  }

  componentWillMount() {
    if (this.props.targetSchoolInfo == null) {
      this.setState({ isRedirectToSchool: true })
    } else {
      const schoolId = this.props.targetSchoolInfo.id
      const period = this.props.targetSchoolInfo.period
      const lotterySchoolId = this.props.cookies.get('lotterySchoolId')
      if (!this.props.lotteryInfo || (schoolId !== lotterySchoolId)) {
        this.props.getLotteryInfo(schoolId, period)
        this.props.cookies.set('lotterySchoolId', schoolId)
      }
    }
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.lotteryInfo !== this.props.lotteryInfo) && this.props.lotteryInfo.length !== 0) {
      let count = 0
      this.setState({
        isShowDetailIndex: this.props.lotteryInfo.map(item => {
          const contentHeight = document.getElementById(`content${count}`).clientHeight
          count += 1
          if (contentHeight > CONTENT_HEIGHT_LIMIT)
            return true
          return false
        })
      })
    }
  }

  attendLottery(lottery, index) {
    if (!isMember(this.props.userinfo)) {
      this.setState({ isRedirectToSetting: true })
    } else {
      this.setState({ isWaitingAttend: true })
      const body = {
        schoolId: this.props.targetSchoolInfo.id,
        period: lottery.period,
        lotteryId: lottery.id
      }
      agent.School.attendLottery(body)
        .then(response => {
          if (response.status === 204) {
            const schoolId = this.props.targetSchoolInfo.id
            const period = this.props.targetSchoolInfo.period
            const tmpIndexes = this.state.isSuccessAttendIndexes
            tmpIndexes[index] = true
            this.props.getLotteryInfo(schoolId, period)
            this.setState({ isWaitingAttend: false, isShowSuccessModal: true, isSuccessAttendIndexes: tmpIndexes })
          } else {
            this.setState({ isWaitingAttend: false })
          }
        })
    }
  }

  setModalIndex(index) {
    this.setState({ modalIndex: index })
  }

  toggleModal() {
    this.setState({ isShowModal: !this.state.isShowModal })
  }

  isLotteryOpen(lottery, isSuccessAttend) {
    const currTime = +new Date()
    return (
      currTime > lottery.begin &&
      currTime < lottery.end &&
      lottery.isOpen &&
      (!isSuccessAttend || !lottery.isAttend)
    )
  }

  getLotteryStateMsg(lottery, isSuccessAttend) {
    const currTime = +new Date()
    if (lottery.winner != null && lottery.winner.length !== 0) {
      return "得獎名單"
    } else if (this.state.isWaitingAttend) {
      return ""
    } else if (isSuccessAttend || lottery.isAttend) {
      return "祝您中獎"
    } else if (currTime > lottery.begin && currTime < lottery.end && lottery.isOpen) {
      return "參加"
    } else if (currTime > lottery.begin && currTime < lottery.end && !lottery.isOpen) {
      return "關閉中"
    } else if (currTime < lottery.begin) {
      return "尚未開放"
    } else if (currTime > lottery.end) {
      return "報名截止"
    }
  }

  handleRedirect(pathParam) {
    this.setState({ [pathParam]: true })
  }

  closeSuccessModal() {
    this.setState({ isShowSuccessModal: false })
  }

  render() {
    if (!this.props.targetSchoolInfo) {
      return <Redirect push to="/school" />
    }

    if (this.state.isRedirectToSetting) {
      return <Redirect push to="/setting/profile?source=lottery" />
    }

    if (this.state.isRedirectToMenu) {
      return <Redirect push to={`/school/${this.props.targetSchoolInfo.id}`} />
    }

    return (
      <Lottery
        lotteryInfo={this.props.lotteryInfo}
        isShowDetailIndex={this.state.isShowDetailIndex}
        isWaitingAttend={this.state.isWaitingAttend}
        isSuccessAttendIndexes={this.state.isSuccessAttendIndexes}
        modalIndex={this.state.modalIndex}
        targetSchoolInfo={this.props.targetSchoolInfo}

        toggleLotteryContent={this.toggleLotteryContent}
        attendLottery={this.attendLottery}
        setModalIndex={this.setModalIndex}

        isLotteryOpen={this.isLotteryOpen}
        getLotteryStateMsg={this.getLotteryStateMsg}

        isShowModal={this.state.isShowModal}
        toggleModal={this.toggleModal}

        isShowSuccessModal={this.state.isShowSuccessModal}
        closeSuccessModal={this.closeSuccessModal}

        handleRedirect={this.handleRedirect}
      />
    )
  }
}

const mapStateToProps = state => ({
  schoolsInfo: state.school.schoolsInfo,
  targetSchoolInfo: state.school.targetSchoolInfo,
  lotteryInfo: state.school.lotteryInfo,
  userinfo: state.member.info,
})

const mapDispatchToProps = dispatch => ({
  getLotteryInfo: (schoolId, period) => {
    dispatch(getLotteryInfo(schoolId, period))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(LotteryContainer))