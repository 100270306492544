import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress'

export default class Withdraw extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      confirm: ""
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleInputChange(event){
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit(e){
    e.preventDefault()
    this.props.withdraw()
  }

  render(){
    return (
      <Dialog open={this.props.isOpen} onClose={() => this.props.setWithdrawModal(false)} aria-labelledby="form-dialog-title">
        <form onSubmit={this.handleSubmit}>
          <DialogTitle id="form-dialog-title">退賽</DialogTitle>
          <DialogContent>
            <DialogContentText>
              退賽後同取消參賽資格，欲再參賽則須重新報名。退費相關資訊請至【我】→【常見問題】
            </DialogContentText>
            <DialogContentText>
              如確定退出賽事，請輸入：確定
            </DialogContentText>
            <TextField
              margin="dense"
              name="confirm"
              fullWidth
              variant="outlined"
              placeholder="確定"
              value={this.state.confirm}
              onChange={this.handleInputChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.setWithdrawModal(false)} color="primary">
              取消
            </Button>
            <Button type="submit" color="primary" disabled={this.state.confirm !== "確定" || this.props.isWaiting}>
              { this.props.isWaiting && <CircularProgress color="primary" size={16} thickness={4} className="mr-2"/> }
              { this.props.isWaiting ? "" : "確定" }
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}
