import React from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

import Button from '@material-ui/core/Button'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { ChevronLeft } from '@material-ui/icons'

const dark = createMuiTheme({
  palette: {
    primary: { main: '#ffffff' }
  },
  typography: {
    useNextVariants: true,
  },
})

export default class FormPayment extends React.Component {
  render() {
    return (
      <div className="page form">
        <div className="bg-cover mask" style={{ backgroundImage: `url(${this.props.targetSchool.coverPhotoL})` }}></div>
        <div className="container">
          <form className="payment-info">
            <div className="cancel">
              <MuiThemeProvider theme={dark}>
                <Button color="primary" onClick={this.props.toPrevPage}>
                  <ChevronLeft fontSize="large" />
                  返回
                </Button>
              </MuiThemeProvider>
            </div>
            <h3 className="title">繳費資訊</h3>
            <div className="content">
              <div className="item">
                <div>銀行代碼</div>
                <div className="value">{this.props.targetForm.payment.bankCode}</div>
              </div>
              <div className="item">
                <div>
                  <div>轉帳帳號</div>
                  <div className="explain">本虛擬帳號僅對應到此報名表單，請勿重複轉入至同一帳號。</div>
                </div>
                <div className="value">{this.props.targetForm.payment.vAccount}</div>
              </div>
              <div className="item">
                <div>轉帳金額</div>
                <div className="value">TWD ${this.props.targetForm.payment.tradeAmt}</div>
              </div>
              <div className="item">
                <div>
                  <div>轉帳期限</div>
                  {
                    this.props.targetForm.payment.rtnCode !== 1 &&
                    <div className="explain">以銀行實際入帳時間為主，為保障參賽權益，請及早付款。</div>
                  }
                </div>
                <div className="value">{dayjs(this.props.targetForm.payment.expireDate).format("YYYY-MM-DD")}</div>
              </div>
              <div className="item">
                <div>繳費狀態</div>
                {
                  this.props.targetForm.payment.rtnCode === 1 &&
                  <div className="paid">已繳費</div>
                }
                {
                  this.props.targetForm.payment.rtnCode !== 1 &&
                  <div className="unpaid">未繳費</div>
                }
              </div>
              <div className="btn-group">
                <Button className="confirm" variant="contained" color="primary" component={Link} to={'/formlist'}>
                  確定
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}
