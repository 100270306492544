import React from 'react'
import { Link } from 'react-router-dom'
import { ChevronLeft } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton';
import PrivacyPolicyComponent from '../../utils/component/PrivacyPolicy'


const PrivacyPolicy = props => {
  return (
    <div className="page">
      <div className="container">
        <div className="top-bar">
          <IconButton component={Link} to={`/setting/about`} className="icon" >
            <ChevronLeft />
          </IconButton>
          <b className="title">隱私權條款</b>
        </div>
        <PrivacyPolicyComponent />
      </div>
    </div>
  )
}

export default PrivacyPolicy