import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import FormList from '../../component/FormList/FormList'

import {
	getTargetForm,
	getFormList,
} from '../../action/form'

class FormListContainer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isRedirectToEdit: false,
			isRedirectToRead: false,
			formId: null,
			schoolId: null,
		}
		this.handleClickForm = this.handleClickForm.bind(this)
		this.isCloseEdit = this.isCloseEdit.bind(this)
		this.getFormLevelName = this.getFormLevelName.bind(this)
		this.getDeadline = this.getDeadline.bind(this)
	}

	componentWillMount() {
		if (!this.props.formList) {
			this.props.getFormList()
		}
	}

	handleClickForm(form) {
		const school = this.props.schoolsInfo.find(schoolInfo => schoolInfo.id === form.organizer)
		if (school) {
			this.props.getTargetForm(form.id)
			if (this.isCloseEdit(form, school) || form.status === 3) {
				this.setState({ isRedirectToRead: true, formId: form.id })
			} else {
				this.setState({ isRedirectToEdit: true, formId: form.id, schoolId: form.organizer })
			}
		}
	}

	getDeadline(form) {
		const school = this.props.schoolsInfo.find(schoolInfo => schoolInfo.id === form.organizer)
		if (!school) return +new Date()
		if (school.isThirdRound) {
			switch (form.level) {
				case 1: return school.editDeadline;
				case 2: return school.finalEditDeadline;
				case 3: return school.thirdRoundEditDeadline;
				default: return +new Date()
			}
		} else if (school.isSingleRound) {
			return school.thirdRoundEditDeadline;
		} else {
			switch (form.level) {
				case 1: return school.editDeadline;
				case 3: return school.thirdRoundEditDeadline;
				default: return +new Date()
			}
		}
	}

	isCloseEdit(form, school) {
		const deadline = this.getDeadline(form)
		const formPeriod = Number(form.period)
		const schoolPeriod = Number(school.period)

		return +new Date() >= deadline || formPeriod !== schoolPeriod
	}

	getFormLevelName(form) {
		const school = this.props.schoolsInfo.find(schoolInfo => schoolInfo.id === form.organizer)
		const level = form.level
		if (!school) return ''
		if (school.isThirdRound) {
			switch (level) {
				case 1: return '初賽'
				case 2: return '複賽'
				case 3: return '決賽'
				default: return ''
			}
		} else if (school.isSingleRound) {
			return '決賽'
		} else {
			switch (level) {
				case 1: return '初賽'
				case 3: return '決賽'
				default: return ''
			}
		}
	}

	render() {
		if (this.state.isRedirectToEdit) {
			return <Redirect push to={`/form/${this.state.schoolId}/update?&id=${this.state.formId}`} />
		}
		if (this.state.isRedirectToRead) {
			return <Redirect push to={`/formRead?id=${this.state.formId}`} />
		}
		return (
			<FormList
				formList={this.props.formList}
				schoolsInfo={this.props.schoolsInfo}

				handleClickForm={this.handleClickForm}
				getFormLevelName={this.getFormLevelName}
				getDeadline={this.getDeadline}
				isCloseEdit={this.isCloseEdit}
			/>
		)
	}
}

const mapStateToProps = state => ({
	formList: state.form.formList,
	userInfo: state.member.info,
	schoolsInfo: state.school.schoolsInfo,
})

const mapDispatchToProps = dispatch => ({
	getFormList: () => {
		dispatch(getFormList())
	},
	getTargetForm: id => {
		dispatch(getTargetForm(id))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(FormListContainer)