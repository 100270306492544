/** Test Account */
export const MCIP = 'mcip'
export const FORTEST = "ForTest"

export const MCIP_FB_ID = "423610201533595"
export const MCIP_HOSTNAME = "app.mcip.app"
export const LINE_COUPON_URL = "/line-coupon"
export const LINE_APP_URL = "https://mcip.app/line"
export const QR_CODE_SCANNER_URL = "/qrcode-scanner"
export const MCIP_STATIC_DATA_URL = 'https://cdn.jsdelivr.net/gh/ngseke/mcip.ml-static@master'

export const environment = {
  production: false,
  lineLoginServiceUrl: "line://ti/p/%40dyz4848r",
  modelServiceUrl: "https://us-central1-mc-integration-platform.cloudfunctions.net"
  // modelServiceUrl: "http://localhost:5000/mc-integration-platform/us-central1"
};

export const lineLoginConfig = {
  client_id: "1626480431"
}

export const firebaseConfig = {
  apiKey: "AIzaSyDuDeYbEy4fl2EghgFFCs0da5yc44oq2OM",
  authDomain: "mc-integration-platform.firebaseapp.com",
  databaseURL: "https://mc-integration-platform.firebaseio.com",
  projectId: "mc-integration-platform",
  storageBucket: "mc-integration-platform.appspot.com",
  messagingSenderId: "139181590761"
}

export const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

export const GROUP = {
  1: "個人組",
  2: "自彈自唱組",
  3: "團體組",
  4: "鋼弦演奏組",
  5: "鋼弦創作組",
  6: "創作組",
  7: "高中組",
  8: "高中演唱組",
  9: "高中演奏組",
  10: "獨唱-國台語組",
  11: "獨唱-外語組",
  12: "獨唱組",
  13: "重唱組",
  14: "學生組",
  15: "社會組",
  16: "樂團組",
  17: "演奏組",
  18: "饒舌組",
  19: "畢業歌",
  20: "大專個人組",
  21: "大專團體組",
  22: "大專創作組",
  23: "大專演奏組",
  24: "鋼弦組",
  25: "本土語組",
  26: "重/對唱組",
  27: "學生獨唱組",
  28: "社會獨唱組",
  29: "重、對唱組",
  30: "客語演唱組",
}

export const GROUP_ICON = {
  1: "user",
  2: "guitar",
  3: "users",
  4: "guitar",
  5: "idea",
  6: "idea",
  7: "user",
  8: "microphone",
  9: "guitar",
  10: "microphone",
  11: "microphone",
  12: "microphone",
  13: "users",
  14: "users",
  15: "users",
  16: "users",
  17: "guitar",
  18: "microphone",
  19: "microphone",
  20: "microphone",
  21: "microphone",
  22: "microphone",
  23: "guitar",
  24: "guitar",
  25: "microphone",
}

export const LEVEL = {
  1: "高中組",
  2: "大專組",
  3: "社會組",
}

export const COMPETITION_STATUS = {
  5: "棄賽",
  4: "已過號",
  3: "等待中",
  2: "進行中",
  1: "比賽結束",
  ABONDON: 5,
  OVER: 4,
  WAITING: 3,
  PLAYING: 2,
  END: 1,
}