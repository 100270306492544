import agent from './agent'

export const Coupon = {
  count: async body => {
    return new Promise((resolve, reject) => {
      return agent.Request.post(`/lineCoupon/count`, body)
        .then(response => resolve(response))
        .catch(err => reject(err))
    })
  },
}