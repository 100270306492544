import React from 'react'
import { Loader } from '../../utils/component/component'

class Coupon extends React.Component {
  render() {
    return (
      <div>
        <Loader />
        <small style={{
          display: "block",
          textAlign: "center",
          opacity: .6,
          marginTop: "-1.8rem"
        }}
        >
          處理中，請稍後
        </small>
      </div >
    )
  }
}

export default Coupon