import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Loader } from '../../utils/component/component'
import { GROUP } from '../../config/environment'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { ChevronLeft, CreditCard } from '@material-ui/icons'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip';

const isEmpty = require("lodash.isempty")

const dark = createMuiTheme({
  palette: {
    primary: { main: '#ffffff' }
  },
  typography: {
    useNextVariants: true,
  },
})

const styles = theme => ({
  formControl: {
    marginTop: 2 * theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

const Members = props => {
  if (props.values && props.values.length !== 0) {
    return (
      <React.Fragment>
        {
          props.values.map((member, index) =>
            <TextField key={member + index} label={`參賽夥伴${index + 1}`}
              fullWidth className={props.classes.formControl}
              InputProps={{ readOnly: true }}
              value={member}
            />
          )
        }
      </React.Fragment>
    )
  } else {
    return null
  }
}

class FormRead extends React.Component {
  render() {
    const { classes, targetForm, targetSchool, userInfo, isPay } = this.props;

    return (
      <div className="page form">
        <div className="bg-cover mask" style={{ backgroundImage: `url(${targetSchool.coverPhotoL})` }}></div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {
                !targetForm &&
                <Loader text="處理中，請稍後" style={{ paddingTop: "20vh" }} />
              }
              {
                targetForm &&
                <form onSubmit={this.handleSubmit}>
                  <div className="cancel">
                    <MuiThemeProvider theme={dark}>
                      <Button color="primary" component={Link} to="/FormList">
                        <ChevronLeft fontSize="large" />
                        返回
				              </Button>
                      {
                        targetForm.payment &&
                        <Button color="primary" component={Link}
                          to={`/form/${targetForm.organizer}/payment?id=${targetForm.id}`}
                        >
                          <CreditCard />
                          繳費資訊
                        </Button>
                      }
                    </MuiThemeProvider>
                  </div>
                  <h4 className="mb-4">
                    <b>{targetSchool.name}</b>
                  </h4>
                  {
                    targetForm.status === 3 &&
                    <Chip label="已棄賽" variant="outlined" color="secondary" />
                  }
                  <TextField label="姓名" name="name" InputProps={{ readOnly: true }}
                    fullWidth className={classes.formControl}
                    value={userInfo.name}
                  />
                  <TextField label="電話" name="phone" InputProps={{ readOnly: true }}
                    fullWidth className={classes.formControl}
                    value={userInfo.phone}
                  />
                  <TextField label="聯絡信箱" name="email" InputProps={{ readOnly: true }}
                    fullWidth className={classes.formControl}
                    value={userInfo.email}
                  />
                  {
                    userInfo.school.level !== 3 &&
                    <TextField label="就讀學校" name="school" InputProps={{ readOnly: true }}
                      fullWidth className={classes.formControl}
                      value={userInfo.school.name}
                    />
                  }
                  <TextField label="參賽組別" name="group" InputProps={{ readOnly: true }}
                    fullWidth className={classes.formControl}
                    value={GROUP[targetForm.group]}
                  />
                  <TextField label="表演曲目" name="song" InputProps={{ readOnly: true }}
                    fullWidth className={classes.formControl}
                    value={targetForm.song}
                  />
                  {
                    targetForm.partners != null &&
                    <Members label="參賽夥伴" values={targetForm.partners}
                      classes={classes}
                    />
                  }
                  {
                    targetForm.equipment.mic !== 0 &&
                    <TextField label="器材 - 麥克風" name="mic" InputProps={{ readOnly: true }}
                      fullWidth className={classes.formControl}
                      value={targetForm.equipment.mic}
                    />
                  }
                  {
                    targetForm.equipment.micStand !== 0 &&
                    <TextField label="器材 - 麥克風架" name="micStand" InputProps={{ readOnly: true }}
                      fullWidth className={classes.formControl}
                      value={targetForm.equipment.micStand}
                    />
                  }
                  {
                    targetForm.equipment.guitar !== 0 &&
                    <TextField label="器材 - 吉他" name="guitar" InputProps={{ readOnly: true }}
                      fullWidth className={classes.formControl}
                      value={targetForm.equipment.guitar}
                    />
                  }
                  {
                    targetForm.equipment.bass !== 0 &&
                    <TextField label="器材 - Bass" name="bass" InputProps={{ readOnly: true }}
                      fullWidth className={classes.formControl}
                      value={targetForm.equipment.bass}
                    />
                  }
                  {
                    targetForm.equipment.cajon !== 0 &&
                    <TextField label="器材 - Cajon" name="cajon" InputProps={{ readOnly: true }}
                      fullWidth className={classes.formControl}
                      value={targetForm.equipment.cajon}
                    />
                  }
                  {
                    targetForm.equipment.KB !== 0 &&
                    <TextField label="器材 - KB" name="KB" InputProps={{ readOnly: true }}
                      fullWidth className={classes.formControl}
                      value={targetForm.equipment.KB}
                    />
                  }
                  {
                    targetForm.equipment.chair !== 0 &&
                    <TextField label="器材 - 椅子" name="chair" InputProps={{ readOnly: true }}
                      fullWidth className={classes.formControl}
                      value={targetForm.equipment.chair}
                    />
                  }
                  <TextField label="其他樂器" name="other" InputProps={{ readOnly: true }}
                    fullWidth className={classes.formControl}
                    value={targetForm.equipment.other === "" ? "無" : targetForm.equipment.other}
                  />
                  <TextField label="效果器" name="effector" InputProps={{ readOnly: true }}
                    fullWidth className={classes.formControl}
                    value={targetForm.equipment.effector === "" ? "無" : targetForm.equipment.effector}
                  />
                  <div className={isPay ? 'payment paid' : 'payment'} >
                    <div className="row no-gutters align-items-center">
                      <div className="col-auto">
                        <div className="icon">
                          <FontAwesomeIcon icon={isPay ? 'check-circle' : 'money-check-alt'} />
                        </div>
                      </div>
                      <div className="col">
                        <div className="text">{isPay ? '已繳費' : '尚未繳費'}</div>
                        {
                          targetForm.code &&
                          <div className="last5Code">
                            匯款帳號後五碼:
                            <span className="code"> {targetForm.code}</span>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  {
                    !isEmpty(targetForm.commonField) &&
                    targetForm.commonField.map((field, index) =>
                      <TextField key={`commonField${index}`} label={field.label} InputProps={{ readOnly: true }}
                        fullWidth className={classes.formControl}
                        value={field.value}
                      />
                    )
                  }
                  {
                    !isEmpty(targetForm.customField) &&
                    targetForm.customField.map((field, index) =>
                      <TextField key={`customField${index}`} label={field.label} InputProps={{ readOnly: true }}
                        fullWidth className={classes.formControl}
                        value={field.value}
                      />
                    )
                  }
                  <div className={classes.formControl}>
                    <Button variant="contained" color="primary" onClick={this.props.handleRedirect}>返回</Button>
                  </div>
                </form>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

FormRead.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FormRead);