import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import PrivacyPolicyComponent from '../../utils/component/PrivacyPolicy'

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

export default class ContractModal extends React.Component {
	render() {
		return (
			<Dialog
				fullScreen
				open={this.props.isShowContractModal}
				scroll="paper"
				TransitionComponent={Transition}
			>
				<DialogTitle id="scroll-dialog-title">隱私權條款</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<PrivacyPolicyComponent />
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => this.props.toggleModal('isShowContractModal')} color="primary">
						確定
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}