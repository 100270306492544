import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import QA from '../../component/School/QA'

class QAContainer extends React.Component {
	constructor(props){
		super(props)
		this.state = {
      collapseID: '',
		}
    this.toggleCollapse = this.toggleCollapse.bind(this)
	}

  toggleCollapse(collapseID){
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }))
  }

	render(){
    if(!this.props.targetSchoolInfo){
      return <Redirect push to={`/school`} />
    }

		return (
			<QA
				targetSchool={this.props.targetSchoolInfo}
				collapseID={this.state.collapseID}
				toggleCollapse={this.toggleCollapse}
			/>
		)
	}
}

const mapStateToProps = state => ({
  targetSchoolInfo: state.school.targetSchoolInfo,
})

const mapDispatchToProps = dispatch => ({ })

export default connect(mapStateToProps, mapDispatchToProps)(QAContainer)