import agent from '../agent/agent'

export const SET_COMPETITION = "SET_COMPETITION"
export const RECEIVE_COMPETITION = "RECEIVE_COMPETITION"

export const getCompetition = () => dispatch => {
	return agent.Competition.getCompetition()
		.then(result => result.json())
		.then(resultJson => dispatch(receiveCompetition(resultJson)))
}

const receiveCompetition = competition => {
	return {
		type: RECEIVE_COMPETITION,
		competition
	}
}

export const setCompetition = competition => {
	return {
		type: SET_COMPETITION,
		competition
	}
}