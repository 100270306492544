import React from 'react'
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom'

import FormListContainer from './Setting/FormListContainer'
import FormReadContainer from './Form/FormReadContainer'
import FormPaymentContainer from './Form/FormPaymentContainer'

import CommentContainer from './Setting/CommentContainer'

import { isMember } from '../utils/function/functions'

import { getCompetition } from '../action/competition'

class Full extends React.Component {
  componentWillMount(){
  	if(this.props.competition == null)
	    this.props.getCompetition()
  }

  componentDidUpdate(prevProps){
    if((prevProps.userInfo !== this.props.userInfo) && this.props.userInfo.name !== null){
	    this.props.getCompetition()
    }
  }

	render(){
		if(this.props.userInfo != null && !isMember(this.props.userInfo)){
      return <Redirect push to="/setting" />
		}
		return (
      <Switch>
        <Route exact path="/formRead" name="formRead" component={FormReadContainer}/>
        <Route exact path="/form/:schoolid/payment" name="formPayment" component={FormPaymentContainer}/>

        <Route exact path="/formList" name="FormList" component={FormListContainer}/>

        <Route exact path="/setting/comment" name="Comment" component={CommentContainer}/>
        <Redirect from="/" to="/news" />
      </Switch>
		)
	}
}

const mapStateToProps = state => ({
  userInfo: state.member.info,
	competition: state.competition.competition
})

const mapDispatchToProps = dispatch => ({
	getCompetition: () => {
		dispatch(getCompetition())
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(Full)