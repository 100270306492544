const MobileDetect = require('mobile-detect')

export const isMember = (userInfo) => {
	return (userInfo.name !== null && userInfo.email !== null && userInfo.phone !== null && userInfo.school !== null)
}

export const isClose = (schoolInfo) => {
	const currTime = +new Date()
	return (!schoolInfo.isOpen || (currTime > schoolInfo.participateDeadline))
}

export const isValidLineId = (lineId) => {
	const regexp = new RegExp('^U[0-9a-f]{32}$')
	return regexp.test(lineId)
}

export const COOKIE = {
	get: cname => {
		var name = cname + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	},
	set: (cname, cvalue) => {
		var d = new Date();
		d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	},
}

export const getDeviceInfo = () => {
	const md = new MobileDetect(window.navigator.userAgent);
	return md
}

export const redirectToFBFanpage = (fbSchemeLink) => {
	const md = getDeviceInfo()
	if (md.is("iOS")) {
		window.location = `fb://page/?id=${fbSchemeLink}`
	} else if (md.is("AndroidOS")) {
		window.location = `fb://page/${fbSchemeLink}`
	} else {
		window.location = `https://www.facebook.com/${fbSchemeLink}`
	}
}

export const isSubArray = (arr, subArr) => {
	if (arr && subArr) {
		for (let i = 0; i < subArr.length; i++) {
			const subItem = subArr[i]
			if (arr.indexOf(subItem) === -1)
				return false
		}
		return true
	}
	return false
}

export const getFormEditDeadline = (school, formLevel) => {
    let editDeadline = +new Date()
    if (school.isSingleRound) {
        editDeadline = school.thirdRoundEditDeadline
    } else if (school.isThidRound) {
        switch (formLevel) {
            case 1: editDeadline = school.editDeadline; break;
            case 2: editDeadline = school.finalEditDeadline; break;
            case 3: editDeadline = school.thirdRoundEditDeadline; break;
            default: break;
        }
    } else if (!school.isSingleRound && !school.isThidRound) {
        switch (formLevel) {
            case 1: editDeadline = school.editDeadline; break;
            case 3: editDeadline = school.thirdRoundEditDeadline; break;
            default: break;
        }
    }
    return editDeadline
}