import {
    RECEIVE_ARTICLE,
} from '../action/article'

const initialState = {
    articles: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_ARTICLE:
            return {
                ...state,
                articles: action.articles
            }
        default:
            return state
    }
}