import React from "react";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";

import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

import { Loader } from "../../utils/component/component";
import LogoAvatar from "../../utils/icons/logo_avatar.png";

dayjs.extend(LocalizedFormat);

export default class News extends React.Component {
  render() {
    return (
      <div className="page">
        {this.props.isWaiting && <Loader text="處理中，請稍後" />}
        {!this.props.isWaiting && (
          <React.Fragment>
            <div className="container">
              <IconButton className="mt-2" component={Link} to={`/news`}>
                <ChevronLeft />
              </IconButton>
            </div>
            <Card className="news-card">
              <div className="container">
                <CardHeader
                  className="card-header"
                  avatar={<Avatar className="avatar" src={LogoAvatar} />}
                  title={this.props.targetArticle.author}
                  subheader={dayjs(this.props.targetArticle.timestamp).format(
                    "LL"
                  )}
                />
                <CardContent className="card-content">
                  <ReactMarkdown source={this.props.targetArticle.article} />
                </CardContent>
              </div>
              {this.props.targetArticle.image && (
                <CardMedia
                  className="cover-photo"
                  component="img"
                  image={this.props.targetArticle.image}
                />
              )}
            </Card>
          </React.Fragment>
        )}
      </div>
    );
  }
}
