import {
	RECEIVE_COMPETITION,
	SET_COMPETITION
} from '../action/competition'

const initialState = {
	competition: null,
}

export default(state = initialState, action) => {
	switch(action.type){
		case RECEIVE_COMPETITION:
			return {
				...state,
				competition: action.competition
			}
		case SET_COMPETITION:
			return {
				...state,
				competition: action.competition
			}
		default: 
			return state
	}
}