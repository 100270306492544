import {
	RECEIVE_USER_INFO,
} from '../action/member'

const initialState = {
	info: null,
}

export default(state = initialState, action) => {
	switch(action.type){
		case RECEIVE_USER_INFO:
			return {
				...state,
				info: action.info
			}
		default:
			return state
	}
}