import React from 'react'
import { Link } from 'react-router-dom'
import { ChevronLeft } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton';
import logoPrimaryBlack from "../../utils/icons/logo_primary-black.svg";

export default class Story extends React.Component {
  render() {
    return (
      <div className="page story">
        <div className="container">
          <div className="top-bar">
            <IconButton component={Link} to={`/setting/about`} className="icon" >
              <ChevronLeft />
            </IconButton>
            <b className="title"></b>
          </div>
          <div className="mcip-logo">
            <img alt="brand" src={logoPrimaryBlack} />
          </div>
          <div className="article">
            <p>樂台計畫是一個大專院校的音樂平台。</p>
            <p>對於<strong>參賽者</strong>，這裡整合了各校的賽事環境，鼓勵不管是自我挑戰，抑或是追夢的你，一同來實現目標。我們希望用更便捷的方式來縮減尋找的距離。</p>
            <p>對於<strong>各大專院校</strong>，我們提供實質的管理後台、金流系統以及曝光管道，讓在資源、人力有限的情況下，能更專心的籌辦賽事。</p>
            <p>最後對於<strong>熱愛音樂的人</strong>，樂台計畫將帶來更多音樂類型的訊息。請隨時關注我們哦！</p>
          </div>
        </div>
      </div>
    )
  }
}
