import React from 'react'
import { Link } from 'react-router-dom'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Divider from '@material-ui/core/Divider'
import { OpenInNew, ChevronLeft } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

const isEmpty = require('lodash.isempty')

const EmptyManuals = props => {
  const schoolId = props.targetSchool.id
	return (
		<div>
      <IconButton component={Link} to={`/school/${schoolId}`} className="icon" >
        <ChevronLeft />
      </IconButton>
      <div className="empty-message">
        <img alt="empty" className="icon" src={require('../../utils/icons/empty-box.svg')} />
        <span>該校無相關簡章</span>
			</div>
		</div>

	)
}

export default class Manual extends React.Component {
	render(){
    if(isEmpty(this.props.targetSchool.manual)) {
      return (
        <div className="container">
          <EmptyManuals targetSchool={this.props.targetSchool} />
        </div>
      )
    }

		return (
      <div className="manuqal page">
        <div className="container">
          <div className="top-bar">
            <IconButton component={Link} to={`/school/${this.props.targetSchool.id}`} className="icon" >
              <ChevronLeft />
            </IconButton>
            <b className="title">{this.props.targetSchool.name}</b>
          </div>
        </div>
        <List>
          {
            this.props.targetSchool.manual.map((item, index) =>
              <div key={`manual${index}`} >
                <div className="container" style={{ background: "white" }}>
                  <ListItem button onClick={() => this.props.handleRedirect(item.link)}>
                    <ListItemText primary={item.name} />
                    <ListItemSecondaryAction>
                      <IconButton>
                        <OpenInNew />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  {
                    index < this.props.targetSchool.manual.length - 1 && <Divider />
                  }
                </div>
              </div>
            )
          }
        </List>
			</div>
		)
	}
}
