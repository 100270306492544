import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { Loader } from "../../utils/component/component";
import MuiReactSelect from "../../utils/component/MuiReactSelect";
import { LEVEL } from "../../config/environment";
import ContractModal from "./ContractModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import { ChevronLeft } from "@material-ui/icons";

import "../../utils/css/setting/_profile.sass";
import logoPrimaryBlack from "../../utils/icons/logo_primary-black.svg";

const LEVEL_SOCIETY = 3;

const styles = (theme) => ({
  formControl: {
    marginTop: 2 * theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

const LotteryRemindMsg = (props) => {
  return (
    <small className="d-block mt-1" style={{ fontWeight: 500, opacity: 0.7 }}>
      個人資料為提供中獎後聯繫依據，相關隱私說明可參照【隱私權條款】。
    </small>
  );
};

const FormRemindMsg = (props) => {
  return (
    <small className="d-block mt-1" style={{ fontWeight: 500, opacity: 0.7 }}>
      個人資料為保障賽事活動依據，相關隱私說明可參照【隱私權條款】。
    </small>
  );
};

const PopularRemindMsg = (props) => {
  return (
    <small className="d-block mt-1" style={{ fontWeight: 500, opacity: 0.7 }}>
      個人資料為確保人氣投票身分依據，相關隱私說明可參照【隱私權條款】。
    </small>
  );
};

const RegisterForm = (props) => {
  const schoolLevel = [];

  for (let key in LEVEL) {
    const value = LEVEL[key];
    schoolLevel.push(
      <option key={value} value={key}>
        {value}
      </option>
    );
  }

  return (
    <form
      className="profile page"
      onSubmit={props.handleSubmit}
      onKeyDown={props.handleKeyDown}
    >
      {props.isSettingInfoMode ? (
        <div className="top-bar">
          <IconButton component={Link} to="/setting" className="icon">
            <ChevronLeft />
          </IconButton>
          <b className="title">會員資訊</b>
        </div>
      ) : (
        <div className="mcip-logo pb-0">
          <img alt="brand" src={logoPrimaryBlack} />
        </div>
      )}
      {props.isShowLotteryRemind && <LotteryRemindMsg />}
      {props.isShowFormRemind && <FormRemindMsg />}
      {props.isShowPopularRemind && <PopularRemindMsg />}
      <TextField
        fullWidth
        label="姓名"
        name="name"
        type="text"
        value={props.registerForm.name}
        required
        className={props.classes.formControl}
        onChange={props.handleRegisterFormChange}
      />
      <TextField
        fullWidth
        label="聯絡電話"
        name="phone"
        type="tel"
        value={props.registerForm.phone}
        required
        className={props.classes.formControl}
        onChange={props.handleRegisterFormChange}
        autoComplete="tel"
      />
      <TextField
        fullWidth
        label="聯絡信箱"
        name="email"
        type="email"
        value={props.registerForm.email}
        required
        className={props.classes.formControl}
        onChange={props.handleRegisterFormChange}
        autoComplete="email"
      />
      <FormControl fullWidth className={props.classes.formControl}>
        <InputLabel htmlFor="level">學制 *</InputLabel>
        <NativeSelect
          inputProps={{ name: "level", id: "level" }}
          value={props.registerForm.school.level}
          onChange={props.handleRegisterFormChange}
        >
          {schoolLevel}
        </NativeSelect>
      </FormControl>
      {Number(props.registerForm.school.level) !== LEVEL_SOCIETY && (
        <FormControl fullWidth className={props.classes.formControl}>
          {
            <MuiReactSelect
              label="學校 *"
              options={props.allSchoolsName}
              value={{
                label: props.registerForm.school.name,
                value: props.registerForm.school.name,
              }}
              onChange={props.handleSchoolNameChange}
              placeholder="您的學校"
            />
          }
        </FormControl>
      )}
      {props.isShowSchoolNameErrorRemind && (
        <small className="error-remind">⚠ 請填入學校名稱</small>
      )}
      {props.isSettingInfoMode && (
        <FormControl fullWidth className={props.classes.formControl}>
          <InputLabel htmlFor="bankCode">銀行代碼</InputLabel>
          <NativeSelect
            inputProps={{ name: "bankCode", id: "bankCode" }}
            value={props.registerForm.bankCode}
            onChange={props.handleRegisterFormChange}
          >
            <option value="" />
            {props.bankCodes &&
              props.bankCodes.map((bankCode) => (
                <option key={bankCode.code} value={bankCode.code}>
                  {bankCode.code} {bankCode.name}
                </option>
              ))}
          </NativeSelect>
        </FormControl>
      )}
      {props.isSettingInfoMode && (
        <TextField
          fullWidth
          label="退款帳戶號碼"
          name="account"
          type="tel"
          value={props.registerForm.account}
          className={props.classes.formControl}
          helperText="為保障個人權益，請謹慎填寫"
          onChange={props.handleRegisterFormChange}
        />
      )}
      {!props.isSettingInfoMode && (
        <div className={props.classes.formControl + " contract"}>
          <Checkbox
            id="isAgreeContract"
            name="isAgreeContract"
            required
            color="primary"
            value={props.isAgreeContract}
            onChange={props.handleInputChange}
          />
          <label htmlFor="isAgreeContract">我已詳閱並同意</label>
          <span
            className="color2"
            onClick={() => props.toggleModal("isShowContractModal")}
          >
            隱私權條款
          </span>
        </div>
      )}
      <div className={props.classes.formControl}>
        <button type="submit" className="gradient-btn">
          {props.isSettingInfoMode ? "更新" : "加入樂台計畫！"}
        </button>
      </div>
    </form>
  );
};

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.props.isSettingInfoMode) {
      this.props.handleUpdateInfo();
    } else {
      this.props.handleSubmitRegisterFrom();
    }
  }

  handleKeyDown(e) {
    if (e.key === "Enter") e.preventDefault();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="update-success-remind animated fadeInDown"
              style={{
                display:
                  this.props.isShowUpdateSuccessRemind === true
                    ? "block"
                    : "none",
                animationDuration: `.5s`,
              }}
            >
              <FontAwesomeIcon icon="check-circle" className="mr-2" />
              更新成功
            </div>
            {this.props.isWaiting && <Loader text="處理中，請稍後" />}
            {!this.props.isWaiting && (
              <RegisterForm
                classes={classes}
                registerForm={this.props.registerForm}
                isSettingInfoMode={this.props.isSettingInfoMode}
                isShowSchoolNameErrorRemind={
                  this.props.isShowSchoolNameErrorRemind
                }
                lineId={this.props.lineId}
                allSchoolsName={this.props.allSchoolsName}
                isShowLotteryRemind={this.props.isShowLotteryRemind}
                isShowFormRemind={this.props.isShowFormRemind}
                isShowPopularRemind={this.props.isShowPopularRemind}
                handleSubmit={this.handleSubmit}
                handleRegisterFormChange={this.props.handleRegisterFormChange}
                handleSchoolNameChange={this.props.handleSchoolNameChange}
                handleKeyDown={this.handleKeyDown}
                toggleModal={this.props.toggleModal}
                redirect={this.props.redirect}
                bankCodes={this.props.bankCodes}
              />
            )}
            <ContractModal
              isShowContractModal={this.props.isShowContractModal}
              toggleModal={this.props.toggleModal}
            />
          </div>
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Profile);
