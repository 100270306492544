import {
	RECEIVE_SCHOOLS_INFO,
	SET_TARGET_SCHOOL,
	CLEAR_TARGET_SCHOOL,
	RECEIVE_LOTTERY_INFO,
} from '../action/school'

const initialState = {
	schoolsInfo: null,
	targetSchoolInfo: null,
	lotteryInfo: null,
}

export default(state = initialState, action) => {
	switch(action.type){
		case RECEIVE_SCHOOLS_INFO:
			return {
				...state,
				schoolsInfo: action.infos
			}
		case SET_TARGET_SCHOOL: 
			return {
				...state,
				targetSchoolInfo: action.school
			}
		case CLEAR_TARGET_SCHOOL:
			return {
				...state,
				targetSchoolInfo: null
			}
		case RECEIVE_LOTTERY_INFO:
			return {
				...state,
				lotteryInfo: action.infos
			}
		default: 
			return state
	}
}