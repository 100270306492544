import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import { MusicNote, LocationCity, Person, EventNote } from '@material-ui/icons'

class Footer extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			targetPage: null,
			value: null,
		}
		this.handleChange = this.handleChange.bind(this)
	}

	componentDidMount(){
		const pathname = window.location.pathname.split('/')
		const firstPage = pathname[1]
		this.handleChange(null, firstPage)
	}

	componentDidUpdate(prevProps, prevState){
		if(prevState.targetPage !== this.state.targetPage && prevState.targetPage){
      document.getElementById(prevState.targetPage).classList.remove('active')
		}
	}

  handleChange(event, value){
    this.setState({ value })
  }

	render(){
		return (
      <BottomNavigation showLabels value={this.state.value} onChange={this.handleChange}
      	className="fixed-bottom"
      >
        <BottomNavigationAction label="最新消息" value="news"
          icon={<EventNote />}
          component={Link} to="/news"
        />
        <BottomNavigationAction label="合作院校" value="school"
          icon={<LocationCity />}
        	component={Link} to="/school"
        />
        <BottomNavigationAction label="賽況資訊" value="competition"
        	icon={<MusicNote />}
        	component={Link} to="/competition"
        />
        <BottomNavigationAction label="我" value="setting"
        	icon={<Person />}
        	component={Link} to="/setting"
        />
      </BottomNavigation>
		)
	}
}

const mapStateToProps = state => ({
	competition: state.competition.competition,
	formList: state.form.formList,
  schoolsInfo: state.school.schoolsInfo,
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
