import agent from './agent'

export const Form = {
	create: async form => {
		return new Promise((resolve, reject) => {
			return agent.Request.post(`/firestoreForm2`, form)
				.then(response => resolve(response))
		})
	},
	update: async form => {
		return new Promise((resolve, reject) => {
			return agent.Request.put(`/firestoreForm2`, form)
				.then(response => resolve(response))
		})
	},
	withdraw: async form => {
		return new Promise((resolve, reject) => {
			return agent.Request.delete(`/firestoreForm2`, form)
				.then(response => resolve(response))
		})
	},
	getFormList: async() => {
		return new Promise((resolve, reject) => {
			return agent.Request.get(`/firestoreForm2`)
				.then(response => resolve(response))
		})
	},
	getPopularFormList: async(schoolId, period) => {
		return new Promise((resolve, reject) => {
			return agent.Request.get(`/firestoreSchool/popular?period=${period}&schoolId=${schoolId}`)
				.then(response => resolve(response))
		})
	},
	vote: async body => {
		return new Promise((resolve, reject) => {
			return agent.Request.post(`/firestoreSchool/popular`, body)
				.then(response => resolve(response))
		})
	}
}