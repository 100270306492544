import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { firestoreConnect } from 'react-redux-firebase'

import News from '../../component/News/News'
const cloneDeep = require('lodash.clonedeep');

class NewsContainer extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      isWaiting: true,
      articles: null,
      targetArticle: null,
      targetId: null,
    }
    this.getArticle = this.getArticle.bind(this)
    this.setArticle = this.setArticle.bind(this)
    this.getArticleCoverPhoto = this.getArticleCoverPhoto.bind(this)
  }

  componentWillMount(){
    const pathname = this.props.location.pathname
    const targetId = pathname.split('/')[2]
    if(targetId){
      this.setState({ targetId }, () => {
        this.getArticle()
      })
    }else{
      this.setState({ isRedirectToList: true })
    }
  }

  componentDidMount(){
    this.setArticle()
  }

  componentDidUpdate(prevProps){
    if(prevProps.articles !== this.props.articles){
      this.setArticle()
    }
  }

  getArticle(){
    this.props.firestore.get({ collection: "appArticle", doc: this.state.targetId })
  }

  setArticle(){
    if(this.props.articles){
      const targetArticle = this.props.articles.filter(article => article.id === this.state.targetId)[0]
      if(targetArticle){
        this.setState({ targetArticle, isWaiting: false }, () => {
          this.getArticleCoverPhoto()
        })
      }else{
        this.setState({ isRedirectToList: true })
      }
    }
  }

  async getArticleCoverPhoto(){
    const { firebase: { storage } } = this.props
    const storageRef = storage().ref()
    const article = cloneDeep(this.state.targetArticle)
    const imagePath = article.image
    if(imagePath){
      const starsRef = storageRef.child(imagePath);
      // Get the download URL
      await starsRef.getDownloadURL().then((url) => {
        article.image = url
      }).catch((error) => {
        console.error(error)
      });
    }
    this.setState({ targetArticle: article, isWaiting: false })
  }

  render(){
    if(this.state.isRedirectToList){
      return <Redirect push to="/news" />
    }
    return (
      <News
        isWaiting={this.state.isWaiting}
        targetArticle={this.state.targetArticle}
      />
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

export default compose(
  firestoreConnect([

  ]),
  connect((state) => {
    return {
      articles: state.firestore.ordered.appArticle
    }
  })
)(connect(mapStateToProps, mapDispatchToProps)(NewsContainer))
