import React from "react";
import { Link } from "react-router-dom";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import { ChevronLeft, ChevronRight, Info } from "@material-ui/icons";
import { OpenInNew, ShieldAccount, ShieldKey, Facebook } from "mdi-material-ui";

import { redirectToFBFanpage } from "../../utils/function/functions";
import { MCIP_FB_ID } from "../../config/environment";

export default class About extends React.Component {
  render() {
    return (
      <div className="page">
        <div className="container">
          <div className="top-bar">
            <IconButton component={Link} to={`/setting`} className="icon">
              <ChevronLeft />
            </IconButton>
            <b className="title">關於</b>
          </div>
        </div>
        <List disablePadding style={{ background: "white" }}>
          <div className="container">
            <ListItem
              button
              onClick={() => {
                redirectToFBFanpage(MCIP_FB_ID);
              }}
            >
              <ListItemIcon>
                <Facebook />
              </ListItemIcon>
              <ListItemText primary="粉絲專頁" />
              <OpenInNew />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem
              button
              component={Link}
              to="/setting/about/privacy-policy"
            >
              <ListItemIcon>
                <ShieldAccount />
              </ListItemIcon>
              <ListItemText primary="隱私權條款" />
              <ChevronRight />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem
              button
              component={Link}
              to="/setting/about/payment-policy"
            >
              <ListItemIcon>
                <ShieldKey />
              </ListItemIcon>
              <ListItemText primary="金流服務條款" />
              <ChevronRight />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem button component={Link} to="/setting/about/story">
              <ListItemIcon>
                <Info />
              </ListItemIcon>
              <ListItemText primary="關於樂台" />
              <ChevronRight />
            </ListItem>
          </div>
        </List>
      </div>
    );
  }
}
