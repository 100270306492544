import agent from '../agent/agent'

export const RECEIVE_COMMENT = "RECEIVE_COMMENT"

export const getComment = lineId => dispatch => {
	return agent.Comment.getComment(lineId)
		.then(result => result.json())
		.then(resultJson => dispatch(receiveComment(resultJson)))

}

const receiveComment = comments => {
	return {
		type: RECEIVE_COMMENT,
		comments
	}
}