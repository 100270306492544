import React from 'react'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GROUP } from '../../config/environment'
import dayjs from 'dayjs'

import { ChevronLeft } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import Chip from '@material-ui/core/Chip'

const Form = props => {
	const percent = props.data.numOfVote * 100 / props.maxValue
	return (
		<div className="d-flex my-3">
			<div className="my-auto" onClick={() => props.setTarget(props.data.id)}>
				{
					props.targetId === props.data.id ?
						<FontAwesomeIcon icon="check-circle" style={{ width: "24px", height: "24px", color: "#007bff" }} /> :
						<div className="rounded-circle border" style={{ width: "24px", height: "24px" }}></div>
				}
			</div>
			<div className="ml-2 w-100">
				<div className="d-flex justify-content-between">
					<b>{`${GROUP[props.data.group]} - ${props.data.song}`}</b>
					<small className="mr-1 mt-auto" style={{ color: "gray" }}>{props.data.numOfVote}</small>
				</div>
				<div className="progress mt-1" style={{ height: "12px" }}>
					<div className="progress-bar" role="progressbar"
						aria-valuenow={props.data.numOfVote} aria-valuemin="0" aria-valuemax={props.maxValue}
						style={{ width: `${percent}%` }}
					></div>
				</div>
			</div>
		</div>
	)
}

export default class Vote extends React.Component {
	render() {
		return (
			<div className="container page vote">
				<div className="top-bar">
					<IconButton component={Link} to={`/school/${this.props.targetSchoolInfo.id}/popular`} className="icon" >
						<ChevronLeft />
					</IconButton>
					{
						this.props.stateMsg &&
						<div className="title">
							<Chip label={this.props.stateMsg} variant="outlined" />
						</div>
					}
				</div>
				<small className="text-right d-block text-right" style={{ opacity: .5 }}>
					<span className="mr-1">{dayjs(this.props.targetSchoolInfo.popularVote.end).format("YYYY-MM-DD HH:mm")}</span>
					<span>截止</span>
				</small>
				{
					this.props.popularFormList &&
					this.props.popularFormList.length !== 0 &&
					this.props.popularFormList.map(form =>
						<Form key={form.id}
							data={form} maxValue={this.props.maxValue} targetId={this.props.targetId}
							setTarget={this.props.setTarget}
						/>
					)
				}
				{
					this.props.popularFormList &&
					this.props.isOpen &&
					<button className="d-block mx-auto gradient-btn mt-4"
						onClick={this.props.vote}
						disabled={this.props.isVoteBtnDisabled()}
					>
						{this.props.isWaiting && <FontAwesomeIcon icon="spinner" className="mr-1" spin />}
						{this.props.isWaiting ? "投票中" : "投票"}
					</button>
				}
			</div>
		)
	}
}