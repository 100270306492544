import React from 'react'
import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'
import { Redirect } from 'react-router-dom'
import qs from 'query-string'

import agent from '../../agent/agent'
import Profile from '../../component/Setting/Profile'

import { COLLEGE, HIGHSCHOOL } from '../../config/school'
import { isMember } from '../../utils/function/functions'

import {
  receiveUserInfo,
} from '../../action/member'

import { getBankCodes } from '../../action/bankCode'

const cloneDeep = require('lodash.clonedeep')
const isEmpty = require('lodash.isempty')

const LEVEL_HIGHSCHOOL = 1
const LEVEL_COLLEGE = 2
const LEVEL_SOCIETY = 3

class ProfileContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      registerForm: {
        name: this.props.userinfo.name ? this.props.userinfo.name : '',
        email: this.props.userinfo.email ? this.props.userinfo.email : '',
        phone: this.props.userinfo.phone ? this.props.userinfo.phone : '',
        school: {
          level: this.props.userinfo.school ? this.props.userinfo.school.level : 2,
          name: this.props.userinfo.school ? this.props.userinfo.school.name : null,
        },
        bankCode: this.props.userinfo.bankCode ? this.props.userinfo.bankCode : '',
        account: this.props.userinfo.account ? this.props.userinfo.account : '',
      },
      isWaiting: true,
      isAgreeContract: false,
      isSettingInfoMode: isMember(this.props.userinfo),

      isShowSchoolNameErrorRemind: false,
      isShowContractModal: false,

      isShowLotteryRemind: false,
      isShowFormRemind: false,
      isShowPopularRemind: false,

      allSchoolsName: [],				// 高中或大學全部的學校
      bankCodes: null,
    }
    this.handleRegisterFormChange = this.handleRegisterFormChange.bind(this)
    this.handleSubmitRegisterFrom = this.handleSubmitRegisterFrom.bind(this)
    this.redirect = this.redirect.bind(this)
    this.handleUpdateInfo = this.handleUpdateInfo.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSchoolNameChange = this.handleSchoolNameChange.bind(this)

    /** set school **/
    this.setSchoolNameOptions = this.setSchoolNameOptions.bind(this)
    this.isValidSchoolName = this.isValidSchoolName.bind(this)
    this.toggleModal = this.toggleModal.bind(this)

    this.setBankCode = this.setBankCode.bind(this)
    this.getUserInfo = this.getUserInfo.bind(this)
  }

  componentWillMount() {
    if (!this.props.bankCodes)
      this.props.getBankCodes()

    const params = this.props.location.search
    const parse = qs.parse(params)
    if ('source' in parse) {
      if (parse.source === "lottery")
        this.setState({ isShowLotteryRemind: true })
      else if (parse.source === "form")
        this.setState({ isShowFormRemind: true })
      else if (parse.source === "popular")
        this.setState({ isShowPopularRemind: true })
    }
  }

  componentDidMount() {
    const schoolLevel = this.state.registerForm.school.level
    this.setSchoolNameOptions(schoolLevel)
    this.setBankCode()
    this.setState({ isShowSchoolNameErrorRemind: false })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bankCodes !== this.props.bankCodes)
      this.setBankCode()
  }

  handleRegisterFormChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    if (name !== "level") {
      this.setState({
        registerForm: {
          ...this.state.registerForm,
          [name]: value
        }
      })
    }
    else {
      this.setState({
        registerForm: {
          ...this.state.registerForm,
          school: {
            name: "",
            level: Number(value)
          }
        },
      })
      this.setSchoolNameOptions(value)
    }
  }

  setSchoolNameOptions(value) {
    const level = Number(value)
    let targetValues = []
    let result = []

    if (level === LEVEL_HIGHSCHOOL)
      targetValues = HIGHSCHOOL
    else if (level === LEVEL_COLLEGE)
      targetValues = COLLEGE

    for (let key in targetValues) {
      const county = targetValues[key]
      for (let school of county) {
        result.push({ label: school[1], value: school[1] })
      }
    }

    this.setState({ allSchoolsName: result })
  }

  redirect(page) {
    this.setState({ [page]: true })
  }

  async handleSubmitRegisterFrom() {
    let form = {}
    form.key = this.props.lineId
    form.form = cloneDeep(this.state.registerForm)
    form.form.school.level = Number(form.form.school.level)
    form.form.school.name = !!form.form.school.name ? form.form.school.name : ''

    if (this.isValidSchoolName(form.form.school.name) || form.form.school.level === LEVEL_SOCIETY) {
      this.setState({
        isWaiting: true,
        isShowSchoolNameErrorRemind: false
      })

      agent.Setting.updateUser(form).then(async result => {
        if (result.status === 204) {
          await this.getUserInfo()
          this.setState({ isRegisterSuccess: true })
        }
        else { alert("會員註冊失敗") }
      })
    } else {
      this.setState({ isShowSchoolNameErrorRemind: true })
    }
  }

  async handleUpdateInfo() {
    let form = {}
    form.key = this.props.lineId
    form.form = cloneDeep(this.state.registerForm)
    form.form.school.level = Number(form.form.school.level)
    form.form.school.name = form.form.school.name == null ? '' : form.form.school.name

    if (this.isValidSchoolName(form.form.school.name) || form.form.school.level === LEVEL_SOCIETY) {
      this.setState({
        isWaiting: true,
        isShowSchoolNameErrorRemind: false
      })
      agent.Setting.updateUser(form).then(async result => {
        if (result.status === 204) {
          await this.getUserInfo()
          this.setState({ isUpdateSuccess: true })
        } else {
          alert("資料更新失敗")
        }
      })
    } else {
      this.setState({ isShowSchoolNameErrorRemind: true })
    }
  }

  isValidSchoolName(name) {
    const filterSchool = this.state.allSchoolsName.filter(school => school.value === name)
    return !isEmpty(filterSchool)
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSchoolNameChange(selectOption) {
    this.setState({
      registerForm: {
        ...this.state.registerForm,
        school: {
          ...this.state.registerForm.school,
          name: selectOption.value
        }
      }
    })
  }

  toggleModal(modal) {
    this.setState({ [modal]: !this.state[modal] })
  }

  /** 設定原用戶銀行代碼選項 **/
  setBankCode() {
    const userBankCode = this.props.userinfo.bankCode
    if (this.props.bankCodes && userBankCode) {
      const targetBank = this.props.bankCodes.find(bank => bank.code === userBankCode)
      if (targetBank) {
        this.setState({
          registerForm: {
            ...this.state.registerForm,
            bankCode: targetBank.code
          }
        })
      }
    }
    this.setState({ isWaiting: false })
  }

  async getUserInfo() {
    await agent.Setting.getUserInfo()
      .then(result => result.json())
      .then(resultJson => {
        this.props.receiveUserInfo(resultJson)
      })
      .catch(error => {
        console.error(error)
        alert("取得資訊時發生錯誤！")
      })
  }

  render() {
    if (this.state.isRegisterSuccess) {
      return <Redirect push to="/setting?register=success" />
    }

    if (this.state.isUpdateSuccess) {
      return <Redirect push to="/setting?update=success" />
    }

    return (
      <Profile
        registerForm={this.state.registerForm}
        userinfo={this.props.info}
        isWaiting={this.state.isWaiting}
        isSettingInfoMode={this.state.isSettingInfoMode}
        lineId={this.props.lineId}
        isShowSchoolNameErrorRemind={this.state.isShowSchoolNameErrorRemind}
        isShowContractModal={this.state.isShowContractModal}

        isShowLotteryRemind={this.state.isShowLotteryRemind}
        isShowFormRemind={this.state.isShowFormRemind}
        isShowPopularRemind={this.state.isShowPopularRemind}

        isAgreeContract={this.state.isAgreeContract}

        handleRegisterFormChange={this.handleRegisterFormChange}
        handleInputChange={this.handleInputChange}
        handleSubmitRegisterFrom={this.handleSubmitRegisterFrom}
        handleUpdateInfo={this.handleUpdateInfo}

        redirect={this.redirect}

        allSchoolsName={this.state.allSchoolsName}
        handleSchoolNameChange={this.handleSchoolNameChange}
        toggleModal={this.toggleModal}

        bankCodes={this.props.bankCodes}
      />
    )
  }
}

const mapStateToProps = state => ({
  lineId: state.member.info == null ? null : state.member.info.id,
  userinfo: state.member.info,
  bankCodes: state.bankCode.bankCodes,
})

const mapDispatchToProps = dispatch => ({
  getBankCodes: () =>
    dispatch(getBankCodes()),
  receiveUserInfo: (info) =>
    dispatch(receiveUserInfo(info)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(ProfileContainer))