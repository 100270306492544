const SCHOOL = [
  {
    value: "north",
    label: "北區",
  },
  {
    value: "central",
    label: "中區",
  },
  {
    value: "south",
    label: "南區",
  },
  {
    value: "highSchool",
    label: "高中組",
  },
  {
    value: "university",
    label: "大專組",
  },
  {
    value: "society",
    label: "社會組",
  },
  {
    value: "fingerstyle",
    label: "演奏類",
  },
  {
    value: "original",
    label: "創作類",
  },
  {
    value: "sing",
    label: "歌唱類",
  },
]

export default {
  SCHOOL
}