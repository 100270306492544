import React from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

import { ChevronLeft } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { Account, CheckCircle } from 'mdi-material-ui'
import CircularProgress from '@material-ui/core/CircularProgress';

import { Loader } from '../../utils/component/component'
import '../../utils/css/school/_lottery.sass'

const contentStyle = (isHide) => {
  if (isHide) {
    return ({
      height: "72px",
      overflowY: "hidden",
      whiteSpace: "pre-wrap"
    })
  } else {
    return ({
      whiteSpace: "pre-wrap"
    })
  }
}

/**
	Button 狀態 :
		可參加、等待中、參加成功、已參加
**/
const LotteryInfo = props => {
  return (
    <div className="lottery-item">
      <img className="img-fluid" src={props.lottery.photo} aria-hidden alt="photo" />
      <div className="card-body">
        <h5 className="card-title">{props.lottery.title}</h5>
        <p id={`content${props.index}`} className="card-text" style={contentStyle(props.isShowDetail)}
          onClick={() => props.toggleLotteryContent(props.index)}
        >
          {props.lottery.content}
        </p>
        {
          props.isShowDetail &&
          <div className="detail-mask" onClick={() => props.toggleLotteryContent(props.index)}></div>
        }
        <small className="card-text d-block mb-3" style={{ opacity: .6 }}>
          {`${dayjs(props.lottery.end).format("YYYY-MM-DD HH:mm")} 截止`}
        </small>
        {
          /** 是否已經抽完了 **/
          props.lottery.winner != null ?
            <button type="button" className="gradient-btn block secondary"	// 得獎名單
              onClick={() => {
                props.toggleModal()
                props.setModalIndex(props.index)
              }}
            >
              {props.getLotteryStateMsg(props.lottery, props.isSuccessAttend)}
            </button>
            :
            /** 還沒抽完 但是否開放參加中 **/
            props.isLotteryOpen(props.lottery, props.isSuccessAttend) ?
              <button type="button" className="gradient-btn block"
                disabled={props.isWaitingAttend || props.isSuccessAttend || props.lottery.isAttend}
                onClick={() => props.attendLottery(props.lottery, props.index)}
              >
                {props.isWaitingAttend && <CircularProgress size={24} style={{ color: 'white' }} />}
                {props.getLotteryStateMsg(props.lottery, props.isSuccessAttend)}
              </button>
              :
              <button type="button" disabled className="gradient-btn block">
                {props.getLotteryStateMsg(props.lottery, props.isSuccessAttend)}
              </button>
        }
      </div>
    </div>
  )
}

const LotteryInfos = props => {
  const schoolId = props.targetSchool.id
  return (
    <div className="page">
      <div className="top-bar">
        <IconButton component={Link} to={`/school/${schoolId}`} className="icon" >
          <ChevronLeft />
        </IconButton>
        <b className="title">{props.targetSchool.name}</b>
      </div>
      {
        props.lotteryInfo.map((lottery, index) =>
          <LotteryInfo key={lottery.id} lottery={lottery} index={index}
            isShowDetail={props.isShowDetailIndex[index]}
            isWaitingAttend={props.isWaitingAttend}
            isSuccessAttend={props.isSuccessAttendIndexes[index]}

            toggleLotteryContent={props.toggleLotteryContent}
            attendLottery={props.attendLottery}
            setModalIndex={props.setModalIndex}

            isLotteryOpen={props.isLotteryOpen}
            getLotteryStateMsg={props.getLotteryStateMsg}
            toggleModal={props.toggleModal}
          />
        )
      }
    </div>
  )
}

const EmptyLottery = props => {
  const schoolId = props.targetSchool.id
  return (
    <div>
      <IconButton component={Link} to={`/school/${schoolId}`} className="icon" >
        <ChevronLeft />
      </IconButton>
      <div className="empty-message">
        <img alt="empty-box" className="icon" src={require('../../utils/icons/empty-box.svg')} />
        <span>尚未舉辦抽獎活動</span>
      </div>
    </div>

  )
}

const WinnerModal = props => {
  return (
    <Dialog open={props.isShowModal} disableBackdropClick maxWidth={false} fullWidth>
      <DialogTitle>得獎名單</DialogTitle>
      <DialogContent style={{ paddingBottom: 0 }}>
        <DialogContentText>
          恭喜以下用戶抽中&nbsp;<strong>{props.lotteryInfo.title}</strong>，
          您可至&nbsp;<strong>我&nbsp;→&nbsp;會員資訊</strong>&nbsp;頁面與主辦單位核對身分兌獎。
      	</DialogContentText>
      </DialogContent>
      {
        props.lotteryInfo.winner &&
        <List style={{ maxHeight: '280px', overflow: 'auto' }}>
          {
            props.lotteryInfo.winner.map((winner, index) => {
              const { school, name } = winner
              return (
                <ListItem key={`winner-${index}`}>
                  <Avatar>
                    <Account />
                  </Avatar>
                  <ListItemText primary={name} secondary={school === '' ? null : school} />
                </ListItem>
              )
            })
          }
        </List>
      }
      <DialogActions>
        <Button onClick={props.toggleModal} color="primary">
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const SuccessModal = props => {
  return (
    <Dialog open={props.isShowSuccessModal} disableBackdropClick maxWidth={false} fullWidth
      className="lottery-attend-success-modal"
    >
      <section className="icon-wrapper animated bounceIn">
        <CheckCircle className="icon" />
      </section>
      <section className="message animated fadeIn fast">參加成功！</section>
      <section className="button-wrapper animated fadeIn fast">
        <Button size="large" color="primary"
          onClick={props.closeSuccessModal}
        >關閉</Button>
      </section>
    </Dialog>
  )
}

export default class Lottery extends React.Component {
  render() {
    const { lotteryInfo, modalIndex, isShowSuccessModal } = this.props
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            {
              lotteryInfo == null &&
              <Loader text="處理中，請稍後" />
            }
            {
              lotteryInfo != null &&
              lotteryInfo.length === 0 &&
              <EmptyLottery
                handleRedirect={this.props.handleRedirect}
                targetSchool={this.props.targetSchoolInfo}
              />
            }
            {
              lotteryInfo != null &&
              lotteryInfo.length !== 0 &&
              <LotteryInfos
                lotteryInfo={lotteryInfo}
                isShowDetailIndex={this.props.isShowDetailIndex}
                isWaitingAttend={this.props.isWaitingAttend}
                isSuccessAttendIndexes={this.props.isSuccessAttendIndexes}
                targetSchool={this.props.targetSchoolInfo}

                toggleLotteryContent={this.props.toggleLotteryContent}
                attendLottery={this.props.attendLottery}
                setModalIndex={this.props.setModalIndex}

                isLotteryOpen={this.props.isLotteryOpen}
                getLotteryStateMsg={this.props.getLotteryStateMsg}
                handleRedirect={this.props.handleRedirect}
                toggleModal={this.props.toggleModal}
              />
            }
            {
              modalIndex != null &&
              <WinnerModal
                lotteryInfo={lotteryInfo[modalIndex]}
                isShowModal={this.props.isShowModal}
                toggleModal={this.props.toggleModal}
              />
            }
            <SuccessModal isShowSuccessModal={isShowSuccessModal}
              closeSuccessModal={this.props.closeSuccessModal}
            />
          </div>
        </div>
      </div>
    )
  }
}
