import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { Loader } from '../../utils/component/component'

import FormCheckout from '../../component/Form/FormCheckout'
import agent from '../../agent/agent'

const queryString = require('query-string')

class RemindContainer extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      isRedirectToFormList: false,
      isRedirectToHome: false,
      isWaiting: true,
      payment: null,
    }
    this.redirect = this.redirect.bind(this)
    this.getPayment = this.getPayment.bind(this)
  }

  componentWillMount(){
    const router = this.props.location
    const urlParams = queryString.parse(router.search)
    const schoolId = urlParams.schoolId
    const period = urlParams.period
    const formId = urlParams.formId
    if(schoolId && period && formId){
      this.getPayment(schoolId, period, formId)
    }else{
      alert("付款資訊錯誤！")
      this.redirect("isRedirectToHome")
    }
  }

  redirect(page){
    this.setState({ [page]: true })
  }

  async getPayment(schoolId, period, formId){
    try{
      const response = await agent.ECPay.getPayment(schoolId, period, formId)
      const payment = await response.json()
      this.setState({ payment, isWaiting: false })
    }catch(err){
      console.error(err)
      alert("付款資訊錯誤！")
      this.redirect("isRedirectToHome")
    }
  }

  render(){
    if(this.state.isRedirectToFormList){
      return <Redirect push to="/formlist" />
    }

    if(this.state.isRedirectToHome){
      return <Redirect push to="/school" />
    }

    if(this.state.isWaiting){
      return <Loader text="取得付款資訊中，請稍後。" />
    }

    return (
      <FormCheckout
        payment={this.state.payment}
        redirect={this.redirect}
      />
    )
  }
}

const mapStateToProps = state => ({
  userInfo: state.member.info,
  schoolsInfo: state.school.schoolsInfo,
})

const mapDispatchToProps = dispatch => ({ })

export default connect(mapStateToProps, mapDispatchToProps)(RemindContainer)