import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { withCookies } from 'react-cookie'

import Popular from '../../component/School/Popular'
import { getPopularFormList } from '../../action/form'

class PopularContainer extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			popularFormList: null,
			isRedirectToVote: false,
			isRedirectToSchool: false,
      isRedirectToMenu: false,
		}
		this.orderFormListByVote = this.orderFormListByVote.bind(this)
    this.handleRedirect = this.handleRedirect.bind(this)
	}

	componentWillMount(){
		if(this.props.targetSchool == null){
			this.setState({ isRedirectToSchool: true })
		}else{
			const schoolId = this.props.targetSchool.id
			const period = this.props.targetSchool.period
			const popularSchoolId = this.props.cookies.get('popularSchoolId')
			if(!this.props.popularFormList || (schoolId !== popularSchoolId)){
				this.props.getPopularFormList(schoolId, period)
				this.props.cookies.set('popularSchoolId', schoolId)
			}else{
				this.orderFormListByVote()
			}
		}
	}

	componentDidUpdate(prevProps){
		if(prevProps.popularFormList !== this.props.popularFormList){
			this.orderFormListByVote()
		}
	}

	orderFormListByVote(){
		this.setState({ popularFormList: this.props.popularFormList.sort((a, b) => a.numOfVote < b.numOfVote ? 1 : -1 ) })
	}

  handleRedirect(pathParam){
    this.setState({ [pathParam]: true })
  }

	render(){
		if(this.state.isRedirectToVote){
			return <Redirect push to={`/school/${this.props.targetSchool.id}/vote`} />
		}

		if(!this.props.targetSchool){
			return <Redirect push to="/school" />
		}

		if(this.state.isRedirectToMenu){
			return <Redirect push to={`/school/${this.props.targetSchool.id}`} />
		}

		return (
			<Popular
				popularFormList={this.state.popularFormList}
				targetSchool={this.props.targetSchool}

				handleRedirect={this.handleRedirect}
			/>
		)
	}
}

const mapStateToProps = state => ({
	popularFormList: state.form.popularFormList,
	targetSchool: state.school.targetSchoolInfo,
})

const mapDispatchToProps = dispatch => ({
	getPopularFormList: (schoolId, period) => {
		dispatch(getPopularFormList(schoolId, period))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(PopularContainer))