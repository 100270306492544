import React from 'react'
import { Link } from 'react-router-dom'

import { Loader } from '../../utils/component/component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact'

import trophy from '../../utils/icons/trophy.png'
import { GROUP } from '../../config/environment'

import { ChevronLeft } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import { Vote } from 'mdi-material-ui'


import '../../utils/css/school/_popular.sass'

const iconStyle = {
	width: "20px",
	height: "20px",
}

const Rank = props => {
  const schoolId = props.targetSchool.id
	return (
		<div>
      <div className="top-bar">
        <IconButton component={Link} to={`/school/${schoolId}`} className="icon" >
          <ChevronLeft />
        </IconButton>
        <b className="title">{props.targetSchool.name}</b>
      </div>
			<MDBTable className="table">
			  <MDBTableHead>
			    <tr>
			      <th scope="col" className="th-rank"><FontAwesomeIcon icon="medal" /></th>
			      <th scope="col" className="th-song">曲名</th>
			      <th scope="col" className="th-vote">票數</th>
			    </tr>
			  </MDBTableHead>
			  <MDBTableBody>
			  	{
			  		props.popularFormList != null &&
			  		props.popularFormList.length !== 0 &&
			  		props.popularFormList.map((info, index) =>
			  			<tr key={info.id}>
			  				<td className="d-flex justify-content-center">
			  					{ index + 1 === 1 && <img alt="trophy" src={trophy} style={iconStyle} /> }
			  					{ index + 1 > 1 && `${index + 1}` }
		  					</td>
			  				<td className="content">
			  					<small className="user">{`${GROUP[info.group]} - ${info.name}`}</small>
			  					<small className="song">{ info.song }</small>
		  					</td>
			  				<td>{info.numOfVote}</td>
			  			</tr>
		  			)
			  	}
			  </MDBTableBody>
			</MDBTable>
		</div>
	)
}

export default class Popular extends React.Component {
	render(){
		return (
			<div className="page popular">
				<div className="container">
					{
						!this.props.popularFormList &&
						<Loader text="處理中，請稍後"/>
					}
					{
						this.props.popularFormList &&
						<Rank
							popularFormList={this.props.popularFormList}
							handleRedirect={this.props.handleRedirect}
							targetSchool={this.props.targetSchool}
						/>
					}
				</div>
				{
					this.props.popularFormList &&
					<Fab className="icon-vote" component={Link} to={`/school/${this.props.targetSchool.id}/vote`}>
						<Vote />
					</Fab>
				}
			</div>
		)
	}
}