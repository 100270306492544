import agent from './agent'

export const Setting = {
	getUserInfo: () => {
		return new Promise((resolve, reject) => {
			return agent.Request.get(`/firestoreMember`)
				.then(response => resolve(response))
		})
	},
	/* Web 只會 Update, create 在加入 Line 時就綁定了 */
	updateUser: form => {
		return agent.Request.put(`/firestoreMember`, form)
	},
}