import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import Setting from '../../component/Setting/Setting'
import { isMember } from '../../utils/function/functions'
import CustomizedSnackbars from '../../utils/component/CustomizedSnackbars'

const queryString = require("query-string")
class SettingContainer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			userInfo: null,
			isWaiting: true,
			isRedirectToRegister: false,
			isRegisterSuccess: false,
			isUpdateSuccess: false,
		}
		this.setUserInfo = this.setUserInfo.bind(this)
		this.setSnackbar = this.setSnackbar.bind(this)
	}

	componentWillMount() {
		this.setSnackbar()
	}

	componentDidMount() {
		if (this.props.userInfo) {
			this.setUserInfo()
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.userInfo !== this.props.userInfo) {
			this.setUserInfo()
		}
	}

	setUserInfo() {
		if (isMember(this.props.userInfo)) {
			this.setState({ isWaiting: false })
		} else {
			this.setState({ isRedirectToRegister: true })
		}
	}

	setSnackbar() {
		const location = this.props.location
		const params = queryString.parse(location.search)
		this.setState({
			isRegisterSuccess: params.register === "success",
			isUpdateSuccess: params.update === "success",
		})
	}

	render() {
		if (this.state.isRedirectToRegister) {
			return <Redirect push to="/setting/profile" />
		}
		return (
			<React.Fragment>
				<CustomizedSnackbars
					anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
					open={this.state.isRegisterSuccess || this.state.isUpdateSuccess}
					autoHideDuration={2500}
					variant="success"
					message={this.state.isRegisterSuccess ? "註冊成功" : "更新成功"}
				/>
				<Setting
					isWaiting={this.state.isWaiting}
					userInfo={this.props.userInfo}
				/>
			</React.Fragment>

		)
	}
}

const mapStateToProps = state => ({
	userInfo: state.member.info,
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(SettingContainer)