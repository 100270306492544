import React from 'react'
import { connect } from 'react-redux'
import QA from '../../component/Setting/QA'
import { getQa } from '../../action/qa'

class QAContainer extends React.Component {
  componentDidMount() {
    if (!this.props.qa) {
      this.props.getQa()
    }
  }

  render() {
    return (
      <QA
        data={this.props.data}
      />
    )
  }
}

const mapStateToProps = state => ({
  data: state.qa.qa,
})

const mapDispatchToProps = dispatch => ({
  getQa: () => {
    dispatch(getQa())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(QAContainer)
