import agent from '../agent/agent'

export const RECEIVE_BANK_CODES = "RECEIVE_BANK_CODES"

export const getBankCodes = () => dispatch => {
  return agent.BankCode.get()
    .then(result => result.json())
    .then(resultJson => {
      dispatch(receiveBankCodes(resultJson))
    })
    .catch(error => {
      console.error(error)
      dispatch(receiveBankCodes([]))
    })
}

export const receiveBankCodes = bankCodes => {
  return {
    type: RECEIVE_BANK_CODES,
    bankCodes
  }
}