import agent from '../agent/agent'

export const RECEIVE_QA = "RECEIVE_QA"

export const receiveQa = qa => {
  return {
    type: RECEIVE_QA,
    qa,
  }
}

export const getQa = () => dispatch => {
  return agent.QA.get()
    .then(resultJson => {
      dispatch(receiveQa(resultJson))
    })
}
