import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types';

import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ChevronRight from '@material-ui/icons/ChevronRight'

import { Loader } from '../../utils/component/component'
import LogoAvatar from '../../utils/icons/logo_avatar.png'

dayjs.extend(LocalizedFormat)

const isEmpty = require("lodash.isempty")
const styles = theme => ({
  cardContent: {
    padding: 0,
  },
});
class List extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newsPath: null,
    }
    this.redirectToNews = this.redirectToNews.bind(this)
  }

  redirectToNews(article) {
    this.setState({ newsPath: article.id })
  }

  render() {
    const { classes } = this.props;

    if (this.state.newsPath) {
      return <Redirect push to={`/news/${this.state.newsPath}`} />
    }

    return (
      <div className="page news-list">
        {
          !isEmpty(this.props.articles) &&
          this.props.articles.map((article, index) => {
            return (
              <Card className="news-card" key={article.id}>
                <div className="container">
                  <CardHeader className="card-header"
                    avatar={
                      <Avatar className="avatar" src={LogoAvatar}></Avatar>
                    }
                    action={
                      <IconButton component={Link} to={`/news/${article.id}`}>
                        <ChevronRight />
                      </IconButton>
                    }
                    title={article.author}
                    subheader={dayjs(article.timestamp).format("LL")}
                  />
                  <CardContent className={classes.cardContent} onClick={() => this.redirectToNews(article)}>
                    <div id={`card-content-${index}`} className="card-content content-hide">
                      <ReactMarkdown source={article.article} />
                    </div>
                    <Typography component={Link} to={`/news/${article.id}`}>... 更多</Typography>
                  </CardContent>

                </div>
                {
                  article.image &&
                  <CardMedia className="cover-photo" component="img" image={article.image} />
                }
              </Card>
            )
          })
        }
        {
          (this.props.isWaiting || this.props.isWaitingLoadMore) &&
          <Loader style={{ padding: this.props.isWaitingLoadMore ? 0 : '3rem 0'}} />
        }
        {
          this.props.errorMsg &&
          <div className="error-msg">{this.props.errorMsg}</div>
        }
      </div>
    )
  }
}

List.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(List);