import {
	RECEIVE_FORM_LIST,
	GET_TARGET_FORM,
	RECEIVE_POPULAR_FORM_LIST,
} from '../action/form'

const initialState = {
	formList: null,
	targetForm: null,
	popularFormList: null,
}

export default(state = initialState, action) => {
	switch(action.type){
		case RECEIVE_FORM_LIST:
			return {
				...state,
				formList: action.infos
			}
		case GET_TARGET_FORM:
			return {
				...state,
				targetForm: state.formList == null ? null : state.formList.filter(form => form.id === action.id)[0]
			}
		case RECEIVE_POPULAR_FORM_LIST:
			return {
				...state,
				popularFormList: action.infos
			}
		default: 
			return state
	}
}