import React from 'react'
import { Redirect } from 'react-router-dom'
import { withCookies } from 'react-cookie'

import Scanner from '../../component/LineCoupon/Scanner'
import { LINE_COUPON_URL, MCIP_HOSTNAME } from '../../config/environment'
import { isValidLineId } from '../../utils/function/functions'

const queryString = require('query-string')

class ScannerContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      urlParams: null,
      isReidrectToHome: false,

      isLegacyMode: false,
      errorMsg: null,
    }
    this.isValidUrlParams = this.isValidUrlParams.bind(this)
    this.isValidQRCode = this.isValidQRCode.bind(this)
    this.handleScan = this.handleScan.bind(this)
    this.handleScanError = this.handleScanError.bind(this)
    this.toggleLegacyMode = this.toggleLegacyMode.bind(this)
  }

  componentWillMount() {
    const location = this.props.location
    const urlParams = queryString.parse(location.search)
    this.setState({ urlParams })
  }

  componentDidMount() {
    this.isValidUrlParams()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.urlParams !== this.state.urlParams) {
      this.isValidUrlParams()
    }
  }

  isValidUrlParams() {
    if (this.state.urlParams) {
      const lineId = this.state.urlParams["id"]
      const isValidParams =
        this.state.urlParams["id"] &&
        this.state.urlParams["openExternalBrowser"] &&
        isValidLineId(lineId)
      if (isValidParams) {
        this.props.cookies.set(`lineId`, lineId)
      } else {
        this.setState({ isReidrectToHome: true })
      }
    }
  }

  handleScan(qrCodeResult) {
    if (qrCodeResult) {
      this.setState({
        qrCodeResult,
        errorMsg: null,
      }, () => {
        this.isValidQRCode()
      })
    } else {
      this.setState({ errorMsg: this.state.isLegacyMode ? "影像無法解析" : null })
    }
  }

  handleScanError(error) {
    console.error(error)
  }

  toggleLegacyMode() {
    this.setState({ isLegacyMode: !this.state.isLegacyMode })
  }

  isValidQRCode() {
    if (this.state.qrCodeResult) {
      try {
        const url = new URL(this.state.qrCodeResult)
        const hostname = url.hostname
        const pathname = url.pathname
        const search = url.search
        const searchParams = queryString.parse(search)
        const isValidUrl =
          hostname === MCIP_HOSTNAME &&
          pathname === LINE_COUPON_URL &&
          searchParams[`utm_campaign`] &&
          searchParams[`utm_source`] &&
          searchParams[`utm_medium`] &&
          searchParams[`redirect_url`]
        this.setState({ errorMsg: isValidUrl ? null : "非活動專用 QR Code" })

        if (isValidUrl) {
          window.location.href = url +
            `&id=${this.state.urlParams.id}`
        }

      } catch (error) {
        console.error(error)
        this.setState({ errorMsg: "影像無法解析" })
      }
    }
  }

  render() {
    if (this.state.isReidrectToHome) {
      return <Redirect push to="/" />
    }

    return (
      <Scanner
        isLegacyMode={this.state.isLegacyMode}
        qrCodeResult={this.state.qrCodeResult}
        errorMsg={this.state.errorMsg}

        handleScan={this.handleScan}
        handleScanError={this.handleScanError}
        toggleLegacyMode={this.toggleLegacyMode}
      />
    )
  }
}

export default withCookies(ScannerContainer)