import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: "#00B9E6",
    },
    secondary: {
      main: "#f50057", // pink
    },
  },
  overrides: {
    MuiButton: {
      raisedPrimary: {
        color: "white",
      },
    },
    MuiStepIcon: {
      root: {
        "&$active": {
          "& $text": {
            fill: "white",
          },
        },
      },
      text: {
        fill: "white",
      },
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottomColor: '#00B9E6',
        },
      },
    },
  },
});
