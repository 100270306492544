import React from "react";
import dayjs from "dayjs";
import Chip from "@material-ui/core/Chip";
import { isClose } from "../../utils/function/functions";
import logoSecondaryWhite from '../../utils/icons/logo_secondary-white.svg'

import "../../utils/css/form/_schoolList.sass";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.setTargetSchool = this.setTargetSchool.bind(this);
  }

  setTargetSchool(e, targetSchool) {
    this.props.setTargetSchool(targetSchool.id);
  }

  render() {
    const { schoolsInfo, schoolTags } = this.props;
    return (
      <div className="page school-list">
        <div className="bg-cover">
          <div className="mcip-logo">
            <img alt="brand" src={logoSecondaryWhite} />
          </div>
        </div>
        <div className="container">
          <div className="filter">
            {schoolTags &&
              schoolTags.map((tag, index) => (
                <Chip
                  key={tag.value}
                  className="filter-chip"
                  label={tag.label}
                  clickable={false}
                  variant={
                    this.props.tags.includes(tag.value) ? "default" : "outlined"
                  }
                  onClick={() => this.props.setTags(tag.value)}
                />
              ))}
          </div>
          {schoolsInfo &&
            schoolsInfo.filter((item) => !isClose(item)).length !== 0 && (
              <React.Fragment>
                <div className="list-title">報名階段院校</div>
                {schoolsInfo.map(
                  (item) =>
                    !isClose(item) && (
                      <div
                        key={item.id}
                        className="school-item"
                        onClick={(e) => this.setTargetSchool(e, item)}
                      >
                        <img
                          alt="avatar"
                          src={item.coverPhoto}
                          className="avatar"
                        />
                        <div className="content">
                          <h6 className="name">{item.name}</h6>
                          <div className="period">第 {item.period} 屆</div>
                          <div className="date-list">
                            {!item.isSingleRound &&
                              item.preliminaryDate &&
                              item.preliminaryDate.length !== 0 && (
                                <span className="date">
                                  <span>初賽日</span>
                                  {item.preliminaryDate.map((date, index) => (
                                    <span key={item.id + date} className="ml-1">
                                      {dayjs(date).format("MM/DD")}
                                    </span>
                                  ))}
                                </span>
                              )}
                            {[
                              !item.isSingleRound,
                              item.isThirdRound,
                              item.finalDate,
                              item.finalDate.length !== 0,
                            ].every((_) => _) && (
                              <span className="date">
                                <span>
                                  {item.isThirdRound ? "複賽日" : "決賽日"}
                                </span>
                                {item.finalDate.map((date, index) => (
                                  <span key={item.id + date} className="ml-1">
                                    {dayjs(date).format("MM/DD")}
                                  </span>
                                ))}
                              </span>
                            )}
                            {item.thirdRoundDate &&
                              item.thirdRoundDate.length !== 0 && (
                                <span className="date">
                                  <span>決賽日</span>
                                  {item.thirdRoundDate.map((date, index) => (
                                    <span key={item.id + date} className="ml-1">
                                      {dayjs(date).format("MM/DD")}
                                    </span>
                                  ))}
                                </span>
                              )}
                          </div>
                          <div className="deadline">
                            <span className="mr-1">報名截止</span>
                            {dayjs(item.participateDeadline).format(
                              "MM/DD HH:mm"
                            )}
                          </div>
                        </div>
                      </div>
                    )
                )}
              </React.Fragment>
            )}
          {schoolsInfo &&
            schoolsInfo.filter((item) => !isClose(item)).length !== 0 && (
              <div className="divider" />
            )}
          <div className="list-title">合作院校</div>
          {schoolsInfo &&
            schoolsInfo.map(
              (item) =>
                isClose(item) && (
                  <div
                    key={item.id}
                    className="school-item"
                    onClick={(e) => this.setTargetSchool(e, item)}
                  >
                    <img
                      alt="avatar"
                      src={item.coverPhoto}
                      className="avatar"
                    />
                    <div className="content">
                      <h6 className="name">{item.name}</h6>
                      <div className="period">第 {item.period} 屆</div>
                      {item.statusMessage && (
                        <div className="status">{item.statusMessage}</div>
                      )}
                    </div>
                  </div>
                )
            )}
        </div>
      </div>
    );
  }
}
