import React from 'react'
import { withCookies } from 'react-cookie'

import Coupon from '../../component/LineCoupon/Coupon'
import CouponErrorPage from '../../component/LineCoupon/CouponErrorPage'
import agent from '../../agent/agent'
import { isValidLineId } from '../../utils/function/functions'

const queryString = require('query-string')

class CouponContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      urlParams: null,
      isInvalidUrlParams: false,
    }
    this.isValidUrlParams = this.isValidUrlParams.bind(this)
    this.countCoupon = this.countCoupon.bind(this)
  }

  componentWillMount() {
    const location = this.props.location
    const urlParams = queryString.parse(location.search)
    this.setState({ urlParams })
  }

  componentDidMount() {
    this.isValidUrlParams()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.urlParams !== this.state.urlParams) {
      this.isValidUrlParams()
    }
  }

  isValidUrlParams() {
    if (this.state.urlParams) {
      const isValid = (
        this.state.urlParams["utm_campaign"] &&
        this.state.urlParams["utm_source"] &&
        this.state.urlParams["utm_medium"] &&
        this.state.urlParams["redirect_url"] &&
        this.state.urlParams["id"] &&
        isValidLineId(this.state.urlParams["id"])
      )
      if (isValid) {
        this.countCoupon()
      } else {
        this.setState({ isInvalidUrlParams: true })
      }
    }
  }

  async countCoupon() {
    const body = {
      "utmCampaign": this.state.urlParams["utm_campaign"],
      "utmSource": this.state.urlParams["utm_source"],
      "utmMedium": this.state.urlParams["utm_medium"],
      "lineId": this.props.cookies.get("lineId")
    }
    try {
      await agent.Coupon.count(body)
    } catch (error) {
      console.error(error)
    }
    window.location.href = this.state.urlParams["redirect_url"]
  }

  render() {
    if (this.state.isInvalidUrlParams) {
      return <CouponErrorPage />
    }

    return (
      <Coupon />
    )
  }
}

export default withCookies(CouponContainer)