import React from 'react'
import QrReader from 'react-qr-reader'

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import { Upload, AlertCircle } from 'mdi-material-ui'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  formControl: {
    margin: theme.spacing.unit,
    marginTop: 2 * theme.spacing.unit,
  },
});


class Scanner extends React.Component {
  constructor(props) {
    super(props)
    this.openImageDialog = this.openImageDialog.bind(this)
  }

  openImageDialog() {
    this.refs.qrReader.openImageDialog()
  }

  render() {
    const { classes } = this.props

    return (
      <div className="page">
        <div className="mcip-logo" style={{ padding: "1rem 0" }}>
          <div className="icon"></div>
          <h4>
            <span style={{ animationDelay: `.65s` }}>樂</span>
            <span style={{ animationDelay: `.73s` }}>台</span>
            <span style={{ animationDelay: `.81s` }}>計</span>
            <span style={{ animationDelay: `.89s` }}>畫</span>
          </h4>
        </div>
        <QrReader
          ref="qrReader"
          delay={500}
          onError={this.props.handleScanError}
          onScan={this.props.handleScan}
          style={{ width: '100%' }}
          legacyMode={this.props.isLegacyMode}
        />
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">模式選擇</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!this.props.isLegacyMode}
                      onChange={this.props.toggleLegacyMode}
                    />
                  }
                  label={this.props.isLegacyMode ? "上傳模式" : "掃描模式"}
                />
              </FormGroup>
              <FormHelperText style={{ lineHeight: "1.2" }}>
                若無法正常使用掃描模式，請將 QR Code 清楚拍下並切換至上傳模式。
              </FormHelperText>
            </FormControl>
            {
              this.props.isLegacyMode &&
              <div className={classes.button}>
                <Button fullWidth variant="outlined" color="primary" onClick={this.openImageDialog}>
                  上傳 QR Code
                  <Upload className={classes.rightIcon} />
                </Button>
              </div>
            }
            {
              this.props.errorMsg &&
              <div className={classes.formControl}
                style={{ color: "#dc3545", display: "flex", alignItems: "center" }}
              >
                <AlertCircle className={classes.leftIcon} />
                <span style={{ fontWeight: 600 }}>{this.props.errorMsg}</span>
              </div>
            }
          </Grid>
        </Grid>
      </div>
    )
  }
}

Scanner.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Scanner);