import React from "react";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/zh-tw"; // load on demand

import { ChevronRight, ChevronLeft } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";

import { isClose, isMember } from "../../utils/function/functions";
import { MCIP } from "../../config/environment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import {
  Help,
  BookOpenVariant,
  TicketConfirmation,
  Vote,
  OpenInNew,
  Calendar,
  Facebook
} from "mdi-material-ui";

import "../../utils/css/form/_school.sass";

const isEmpty = require("lodash.isempty");

const dark = createMuiTheme({
  palette: {
    primary: { main: "#ffffff" },
  },
  typography: {
    useNextVariants: true,
  },
});

dayjs.extend(relativeTime);

export default class School extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleClickParticipate = this.handleClickParticipate.bind(this);
    this.handleClickPopularBtn = this.handleClickPopularBtn.bind(this);
    this.handleClickQABtn = this.handleClickQABtn.bind(this);
    this.handleClickManualBtn = this.handleClickManualBtn.bind(this);
    this.handleClickLotteryBtn = this.handleClickLotteryBtn.bind(this);
  }

  componentWillMount() {
    window.scrollTo(0, 0);
  }

  handleClickParticipate() {
    if (
      !isClose(this.props.targetSchoolInfo) &&
      isMember(this.props.userinfo)
    ) {
      this.props.handleRedirect("isRedirectToParticipate");
    } else if (
      !isClose(this.props.targetSchoolInfo) &&
      !isMember(this.props.userinfo)
    ) {
      this.props.handleRedirect("isRedirectToProfile");
    }
  }

  handleClickPopularBtn() {
    if (this.props.isPopularBtnEnabled) {
      this.props.handleRedirect("isRedirectToPopular");
    }
  }

  handleClickQABtn() {
    if (this.props.isQABtnEnabled) {
      this.props.handleRedirect("isRedirectToQA");
    }
  }

  handleClickManualBtn() {
    if (this.props.isManualBtnEnabled) {
      this.props.handleRedirect("isRedirectToManual");
    }
  }

  handleClickLotteryBtn() {
    if (this.props.isLotteryBtnEnabled) {
      this.props.handleRedirect("isRedirectToLottery");
    }
  }

  render() {
    return (
      <div className="page school">
        <div
          className="bg-cover mask"
          style={{
            backgroundImage: `url(${this.props.targetSchoolInfo.coverPhotoL})`,
          }}
        >
          <div className="header">
            <div className="container">
              <div className="row no-gutters">
                <div className="col-auto">
                  <MuiThemeProvider theme={dark}>
                    <IconButton
                      color="primary"
                      onClick={() =>
                        this.props.handleRedirect("isRedirectToHome")
                      }
                    >
                      <ChevronLeft style={{ fontSize: `3rem` }} />
                    </IconButton>
                  </MuiThemeProvider>
                </div>
                <div className="col">
                  {this.props.targetSchoolInfo.id !== MCIP && (
                    <div className="period">
                      第 {this.props.targetSchoolInfo.period} 屆
                    </div>
                  )}
                  <h2 className="name">{this.props.targetSchoolInfo.name}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          {this.props.targetSchoolInfo.id !== MCIP && (
            <div className="date-list">
              {!this.props.targetSchoolInfo.isSingleRound &&
                !isEmpty(this.props.targetSchoolInfo.preliminaryDate) && (
                  <div className="date">
                    <Calendar className="icon" />
                    <span>初賽日期&nbsp;</span>
                    {this.props.targetSchoolInfo.preliminaryDate.map(
                      (date, index) => (
                        <span key={this.props.targetSchoolInfo.id + date}>
                          {dayjs(date).format("MM/DD")}&nbsp;
                        </span>
                      )
                    )}
                  </div>
                )}
              {!this.props.targetSchoolInfo.isSingleRound &&
                this.props.targetSchoolInfo.isThirdRound &&
                !isEmpty(this.props.targetSchoolInfo.finalDate) && (
                  <div className="date">
                    <Calendar className="icon" />
                    <span>複賽日期&nbsp;</span>
                    {this.props.targetSchoolInfo.finalDate.map(
                      (date, index) => (
                        <span key={this.props.targetSchoolInfo.id + date}>
                          {dayjs(date).format("MM/DD")}&nbsp;
                        </span>
                      )
                    )}
                  </div>
                )}
              {this.props.targetSchoolInfo.thirdRoundDate &&
                !isEmpty(this.props.targetSchoolInfo.thirdRoundDate) && (
                  <div className="date">
                    <Calendar className="icon" />
                    <span>決賽日期&nbsp;</span>
                    {this.props.targetSchoolInfo.thirdRoundDate.map(
                      (date, index) => (
                        <span key={this.props.targetSchoolInfo.id + date}>
                          {dayjs(date).format("MM/DD")}&nbsp;
                        </span>
                      )
                    )}
                  </div>
                )}
            </div>
          )}
          {this.props.targetSchoolInfo.isECPay && (
            <div className="mcip-payment">
              <span className="icon">
                <FontAwesomeIcon icon={[`fas`, `money-check-alt`]} />
              </span>
              <span className="text">樂台計劃金流服務</span>
            </div>
          )}
          {this.props.targetSchoolInfo.id !== MCIP && (
            <div className="participate">
              <Button
                variant="outlined"
                fullWidth
                color="primary"
                className="button"
                onClick={this.handleClickParticipate}
                disabled={isClose(this.props.targetSchoolInfo)}
              >
                <div className="content">
                  <div className="text">
                    {isClose(this.props.targetSchoolInfo)
                      ? "報名已截止"
                      : "報名"}
                  </div>
                  <div className="date">
                    <span>
                      ~{" "}
                      {dayjs(
                        this.props.targetSchoolInfo.participateDeadline
                      ).format("YYYY-MM-DD HH:mm")}
                    </span>
                    {!isClose(this.props.targetSchoolInfo) && (
                      <span>
                        (約{" "}
                        {dayjs(this.props.targetSchoolInfo.participateDeadline)
                          .locale("zh-tw")
                          .fromNow()
                          .replace(/內/g, `後`)}
                        )
                      </span>
                    )}
                  </div>
                </div>
              </Button>
            </div>
          )}
          <List className="list">
            <ListItem button onClick={this.props.redirectToFB}>
              <ListItemIcon>
                <Facebook />
              </ListItemIcon>
              <ListItemText primary="粉絲專頁" />
              <OpenInNew />
            </ListItem>

            <Divider variant="inset" component="li" />

            <ListItem
              button={this.props.isQABtnEnabled}
              onClick={this.handleClickQABtn}
              disabled={!this.props.isQABtnEnabled}
            >
              <ListItemIcon>
                <Help />
              </ListItemIcon>
              <ListItemText primary="常見問題" />
              {this.props.isQABtnEnabled && (
                <div className="count">
                  {this.props.targetSchoolInfo.QA.length}
                </div>
              )}
              {this.props.isQABtnEnabled && <ChevronRight />}
            </ListItem>
            <Divider variant="inset" />
            {this.props.targetSchoolInfo.id !== MCIP && (
              <ListItem
                button={this.props.isManualBtnEnabled}
                onClick={this.handleClickManualBtn}
                disabled={!this.props.isManualBtnEnabled}
              >
                <ListItemIcon>
                  <BookOpenVariant />
                </ListItemIcon>
                <ListItemText primary="參賽簡章" />
                {this.props.isManualBtnEnabled && (
                  <div className="count">
                    {this.props.targetSchoolInfo.manual.length}
                  </div>
                )}
                {this.props.isManualBtnEnabled && <ChevronRight />}
              </ListItem>
            )}
            <Divider variant="inset" />

            <ListItem
              button={this.props.isLotteryBtnEnabled}
              onClick={this.handleClickLotteryBtn}
              disabled={!this.props.isLotteryBtnEnabled}
            >
              <ListItemIcon>
                <TicketConfirmation />
              </ListItemIcon>
              <ListItemText primary="抽獎活動" />
              {this.props.isLotteryBtnEnabled && (
                <div className="count">{this.props.lotteryInfo.length}</div>
              )}
              {this.props.isLotteryBtnEnabled && <ChevronRight />}
            </ListItem>
            <Divider variant="inset" />
            {this.props.targetSchoolInfo.id !== MCIP && (
              <ListItem
                button={this.props.isPopularBtnEnabled}
                onClick={this.handleClickPopularBtn}
                disabled={!this.props.isPopularBtnEnabled}
              >
                <ListItemIcon>
                  <Vote />
                </ListItemIcon>
                <ListItemText primary="最佳人氣獎" />
                {this.props.isPopularBtnEnabled && <ChevronRight />}
              </ListItem>
            )}
          </List>
        </div>
      </div>
    );
  }
}
