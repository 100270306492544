import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import FormRead from '../../component/Form/FormRead'

class FormReadContainer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isRedirectBack: false,
			isPay: false,		// 繳費狀態
			targetSchool: null,
		}
		this.handleRedirect = this.handleRedirect.bind(this)
		this.setTargetSchool = this.setTargetSchool.bind(this)
	}

	componentWillMount() {
		if (this.props.formList == null) {
			this.setState({ isRedirectBack: true })
		} else {
			const targetForm = this.props.targetForm
			const targetSchool = this.props.schoolsInfo.filter(school => school.id === targetForm.organizer)[0]
			this.setTargetSchool(targetSchool)
			this.setState({ isPay: targetForm.status === 1 })
		}
	}

	handleRedirect() {
		this.setState({ isRedirectBack: true })
	}

	setTargetSchool(targetSchool) {
		this.setState({ targetSchool: targetSchool })
	}

	render() {
		if (this.state.isRedirectBack) {
			return <Redirect push to="/FormList" />
		}

		return (
			<FormRead
				targetForm={this.props.targetForm}
				userInfo={this.props.userInfo}
				targetSchool={this.state.targetSchool}
				isPay={this.state.isPay}

				handleRedirect={this.handleRedirect}
			/>
		)
	}
}

const mapStateToProps = state => ({
	targetForm: state.form.targetForm,
	formList: state.form.formList,
	userInfo: state.member.info,
	schoolsInfo: state.school.schoolsInfo,
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(FormReadContainer)