import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import List from '../../component/News/List'
import agent from '../../agent/agent'
import { receiveArticle } from "../../action/article"

const LIMIT = 3
class ListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isWaiting: true,
      isWaitingLoadMore: false,
      isAutoFetchAtBottom: false,
      articles: null,
      errorMsg: null,
    }
    this.getArticles = this.getArticles.bind(this)
    this.setArticles = this.setArticles.bind(this)
    this.getMoreArticle = this.getMoreArticle.bind(this)
    this.handleWindowScroll = this.handleWindowScroll.bind(this)
  }

  componentWillMount() {
    if (!this.props.articles) {
      this.getArticles()
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleWindowScroll);
    this.setArticles()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.articles !== this.props.articles) {
      this.setArticles()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleWindowScroll);
  }

  async getArticles(afterArticleId) {
    await agent.Article.get(afterArticleId, LIMIT)
      .then(result => result.json())
      .then(resultJson => {
        const articles = this.props.articles
        const data = articles ? [...articles, ...resultJson] : resultJson
        const isAutoFetchAtBottom = resultJson.length >= LIMIT
        this.setState({ isAutoFetchAtBottom }, () => {
          if (isAutoFetchAtBottom) {
            /** restart listening when is not end */
            window.addEventListener('scroll', this.handleWindowScroll);
          }
          this.props.receiveArticle(data)
        })
      })
      .catch(error => {
        console.error(error)
        this.setState({
          articles: [],
          isWaiting: false,
          errorMsg: "發生異常錯誤"
        })
      })
  }

  setArticles() {
    const articles = this.props.articles
    this.setState({
      articles,
      isWaiting: articles === null,
      isWaitingLoadMore: false
    })
  }

  async getMoreArticle() {
    const lastArticleId = this.props.articles[this.props.articles.length - 1].id
    this.setState({ isWaitingLoadMore: true })
    this.getArticles(lastArticleId)
  }

  handleWindowScroll() {
    const isBottom = (window.innerHeight + window.scrollY) >= document.body.offsetHeight
    if (isBottom && this.state.isAutoFetchAtBottom) {
      /** remove listening immediately, prevent timing issue */
      window.removeEventListener('scroll', this.handleWindowScroll);
      this.getMoreArticle()
    }
  }

  render() {
    return (
      <List
        articles={this.state.articles}
        isWaiting={this.state.isWaiting}
        isWaitingLoadMore={this.state.isWaitingLoadMore}
        errorMsg={this.state.errorMsg}
      />
    )
  }
}

const mapStateToProps = state => ({
  articles: state.article.articles
})

const mapDispatchToProps = dispatch => ({
  receiveArticle: articles => {
    dispatch(receiveArticle(articles))
  }
})

export default compose(
  firestoreConnect([

  ]),
  connect((state) => {
    return {
      articles: state.firestore.ordered.appArticle
    }
  })
)(connect(mapStateToProps, mapDispatchToProps)(ListContainer))
