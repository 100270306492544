import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ChevronLeft } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import '../../utils/css/school/_QA.sass'
const isEmpty = require('lodash.isempty')

const EmptyQA = props => {
  const schoolId = props.targetSchool.id
  return (
    <div>
      <IconButton component={Link} to={`/school/${schoolId}`} className="icon" >
        <ChevronLeft />
      </IconButton>
      <div className="empty-message">
        <img alt="empty-box" className="icon" src={require('../../utils/icons/empty-box.svg')} />
        <span>目前該校無設列問答</span>
      </div>
    </div>
  )
}

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

class QA extends React.Component {
  render() {
    const { classes, targetSchool } = this.props

    if (isEmpty(targetSchool.QA)) {
      return (
        <div className="container">
          <EmptyQA targetSchool={targetSchool} />
        </div>
      )
    }

    return (
      <div className="QA page">
        <div className="container">
          <div className="top-bar">
            <IconButton component={Link} to={`/school/${targetSchool.id}`} className="icon" >
              <ChevronLeft />
            </IconButton>
            <b className="title">{targetSchool.name}</b>
          </div>
        </div>
        {
          targetSchool.QA.map((item, index) => {
            return (
              <ExpansionPanel key={`qa-${index}`} className="expansion-panel">
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className="expansion-panel-summary">
                  <div className="container">
                    <Typography className={classes.heading}>{item.question}</Typography>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="expansion-panel-detail">
                  <div className="container">
                    <Typography>{item.answer}</Typography>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )
          })
        }
      </div>
    )
  }
}

QA.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(QA);