import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import FormPayment from '../../component/Form/FormPayment'
import { Loader } from '../../utils/component/component'
import { getFormEditDeadline } from '../../utils/function/functions'
import { getTargetForm } from '../../action/form'

const queryString = require('query-string')

class FormPaymentContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isRedirectToHome: false,
      id: null,
      targetForm: null,
      targetSchool: null,
    }
    this.handleRedirect = this.handleRedirect.bind(this)
    this.toPrevPage = this.toPrevPage.bind(this)
    this.setTarget = this.setTarget.bind(this)
  }

  componentWillMount() {
    const router = this.props.location
    const urlParams = queryString.parse(router.search)
    const formId = urlParams.id
    this.setState({
      isRedirectToHome: formId ? false : true,
      id: formId,
    })

  }

  componentDidMount() {
    this.setTarget()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.formList !== this.props.formList)
      this.setTarget()
  }

  handleRedirect(page) {
    this.setState({ [page]: true })
  }

  setTarget() {
    if (this.props.formList) {
      const targetForm = this.props.formList.filter(form => form.id === this.state.id)[0]
      const targetSchool = this.props.schoolsInfo.filter(school => school.id === targetForm.organizer)[0]
      this.props.getTargetForm(this.state.id)
      this.setState({
        isRedirectToHome: targetForm && targetForm.payment ? false : true,
        targetForm,
        targetSchool,
      })
    }
  }

  toPrevPage() {
    const { targetForm, targetSchool } = this.state
    const targetEditDeadline = getFormEditDeadline(targetSchool, targetForm.level)
    this.setState({
      prevUrl: (+new Date() >= targetEditDeadline || targetForm.status === 3) ?
        `/formRead?id=${targetForm.id}` :
        `/form/${targetForm.organizer}/update?&id=${targetForm.id}`
    })
  }

  render() {
    if (this.state.isRedirectToHome) {
      return <Redirect push to="/school" />
    }

    if (this.state.prevUrl) {
      return <Redirect push to={this.state.prevUrl} />
    }

    if (!this.state.targetForm || !this.state.targetSchool) {
      return <Loader text="處理中，請稍後" />
    }

    return (
      <FormPayment
        userInfo={this.props.userInfo}
        targetForm={this.state.targetForm}
        targetSchool={this.state.targetSchool}

        handleRedirect={this.handleRedirect}
        toPrevPage={this.toPrevPage}
      />
    )
  }
}

const mapStateToProps = state => ({
  formList: state.form.formList,
  userInfo: state.member.info,
  schoolsInfo: state.school.schoolsInfo,
})

const mapDispatchToProps = dispatch => ({
  getTargetForm: id => {
    dispatch(getTargetForm(id))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(FormPaymentContainer)