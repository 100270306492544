import agent from './agent'

export const ECPay = {
  checkout: async body => {
    return new Promise((resolve, reject) => {
      return agent.Request.post(`/aioCheckOutATM`, body)
        .then(response => resolve(response))
        .catch(err => reject(err))
    })
  },
  getPayment: async (schoolId, period, formId) => {
    const params = `schoolId=${schoolId}&` +
      `period=${period}&` +
      `formId=${formId}`
    return new Promise((resolve, reject) => {
      return agent.Request.get(`/paymentInfo?${params}`)
        .then(response => resolve(response))
        .catch(err => reject(err))
    })
  }
}