import { environment } from '../config/environment'
import { COOKIE } from '../utils/function/functions'

import { Form } from './form'
import { Setting } from './setting'
import { School } from './school'
import { Comment } from './comment'
import { Competition } from './competition'
import { ECPay } from './ecpay'
import { BankCode } from './bankCode'
import { Article } from './article'
import { Coupon } from './coupon'
import { QA } from './qa'

const Request = {
  get: (url) => {
    return fetch(`${environment.modelServiceUrl}${url}`, {
      method: 'GET',
      headers: {
				lineid: COOKIE.get('lineId'),
      },
    })
    .then(response => response)
  },
  post: (url, body) => {
    return fetch(`${environment.modelServiceUrl}${url}`, {
      method: 'POST',
      headers: {
	      'Accept': 'application/json',
				"Content-Type": "application/json",
				lineid: COOKIE.get('lineId'),
      },
      body: JSON.stringify(body),
    })
    .then(response => response)
  },
  put: (url, body) => {
    return fetch(`${environment.modelServiceUrl}${url}`, {
      method: 'PUT',
      headers: {
	      'Accept': 'application/json',
				"Content-Type": "application/json",
				lineid: COOKIE.get('lineId'),
      },
      body: JSON.stringify(body),
    })
    .then(response => response)
  },
  delete: (url, body) => {
    return fetch(`${environment.modelServiceUrl}${url}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        "Content-Type": "application/json",
        lineid: COOKIE.get('lineId'),
      },
      body: JSON.stringify(body),
    })
    .then(response => response)
  }
}

const recaptcha = {
  verify: (body) => {
    return new Promise((resolve, reject) => {
      return Request.post('/recaptchaService', body)
        .then(response => resolve(response))
        .catch(err => reject(err))
    })
  }
}

export default {
	Request,
	Form,
	Setting,
	School,
	Comment,
  Competition,
  recaptcha,
  ECPay,
  BankCode,
  Article,
  Coupon,
  QA,
}
