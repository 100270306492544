import React from 'react'
import { MDBBadge } from "mdbreact"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import ReactScroll from 'react-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Loader } from '../../utils/component/component'
import { COMPETITION_STATUS, GROUP } from '../../config/environment'

import '../../utils/css/competition/_competition.sass'

const ScrollElement = ReactScroll.Element
const isEmpty = require('lodash.isempty')

const listStyle = (status) => {
	if(status === COMPETITION_STATUS["PLAYING"]){
		return `item active`
	}else if(status === COMPETITION_STATUS["END"] || status === COMPETITION_STATUS["ABONDON"]){
		return `item disabled`
	}else{
		return `item`
	}
}

const statusBadgeColor = (status) => {
  if(status === COMPETITION_STATUS["WAITING"]){
    return `success`
  } else if(status === COMPETITION_STATUS["PLAYING"]){
    return `danger`
  } else if(status === COMPETITION_STATUS["END"]){
    return `grey darken-1`
  } else if(status === COMPETITION_STATUS["OVER"]){
    return `brown darken-1`
  } else if(status === COMPETITION_STATUS["ABONDON"]){
    return `amber darken-2`
  }
}

const EmptyCompetition = props => {
	return (
    <div className="empty-message">
      <div className="sleeping-z-s"></div>
      <span>目前尚無進行中的賽事</span>
    </div>
	)
}

const Header = props => {

  return (
    <div className={`header ${props.className}`}>
      <div className="school">
        <span>{props.targetSchoolName}</span>
      </div>
      <div className="group" key={props.groupName}>{props.groupName}</div>
      <div className="last-update">
        {
          props.fromUpdateTime != null &&
          <span>{props.fromUpdateTime}更新</span>
        }
      </div>
    </div>
  )
}

const OneCompetition = props => {
  const groupName = GROUP[props.groupIndexes[props.tabListIndex]]
	return (
		<Tabs className="competition" selectedIndex={props.tabListIndex} onSelect={index => props.setTabListIndex(index)}>
      <Header
        className=''
        targetSchoolName={props.targetSchoolName}
        groupName={groupName}
        groupIndexes={props.groupIndexes}
        playingGroupIndex={props.playingGroupIndex}
        fromUpdateTime={props.fromUpdateTime}
      />
      <Header
        className={`scroll ${props.isScrolledHeaderShow && `show`}`}
        targetSchoolName={props.targetSchoolName}
        groupName={groupName}
        groupIndexes={props.groupIndexes}
        playingGroupIndex={props.playingGroupIndex}
        fromUpdateTime={props.fromUpdateTime}
      />
      {
        <TabList className="tab-list">
          {
            !isEmpty(props.groupIndexes) &&
            props.groupIndexes.map((_, index) =>
              <Tab className="item" selectedClassName="active" key={`tabIndex${_}`}>
                {
                  (index === props.playingGroupIndex) &&
                  <FontAwesomeIcon className="icon" icon="music"/>
                }
                { GROUP[_] }
              </Tab>
            )
          }
        </TabList>
      }
			{
        !isEmpty(props.groupIndexes) &&
        props.groupIndexes.map(groupIndex =>
          <TabPanel className="list" key={`groupInfo${groupIndex}`}>
              {
                props.targetCompetition[groupIndex].map((info, index) =>
                  <ScrollElement key={`group${index}${info.song}`} name={`${groupIndex}${index}`}>
                    <li className={listStyle(info.status)}>
                      <div className="container-fluid">
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <div className="index">
                              <span className="number">{index + 1}</span>
                              <FontAwesomeIcon className="icon" icon="music"/>
                            </div>
                          </div>
                          <div className="col">
                            <div className="info">
                              <span className="song">{info.song}</span>
                              {
                                info.name &&
                                <span className="name">{info.name}</span>
                              }
                            </div>
                          </div>
                          <div className="col-auto status">
                            <MDBBadge color={statusBadgeColor(info.status)} pill>{COMPETITION_STATUS[info.status]}</MDBBadge>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ScrollElement>
                )
              }
          </TabPanel>
        )
			}
		</Tabs>
	)
}

const OneMoreCompetition = props => {
	if(props.targetCompetition === ''){
		return (
			<div>
        <h5 className="notice-title">目前進行多項賽事，請先選擇一場比賽唷</h5>
        <div className="row no-gutters">
  				{
  					props.competition.map(item => {
              const targetSchool = props.schoolsInfo.filter(schoolInfo => schoolInfo.id === item.id)[0]
              const schoolImgLink = targetSchool.coverPhotoL
              const schoolName = targetSchool.name
  						return (
        				<div className="col-12" key={item.id}>
                  <div className="school-info-img-1" style={{backgroundImage: `url(${schoolImgLink})`}}
                    onClick={ () => props.setTargetSchool(item.id) }
                  >
                    <div className="mask"></div>
                    <h6>{schoolName}</h6>
                  </div>
                </div>
  						)
  					})
  				}
        </div>
			</div>
		)
	}else{
		return (
			<OneCompetition
				targetCompetition={props.targetCompetition}
				targetSchoolName={props.targetSchoolName}
				groupIndexes={props.groupIndexes}
        tabListIndex={props.tabListIndex}
        playingGroupIndex={props.playingGroupIndex}
        setTabListIndex={props.setTabListIndex}
				fromUpdateTime={props.fromUpdateTime}
        isScrolledHeaderShow={props.isScrolledHeaderShow}
			/>
		)
	}

}

export default class Competition extends React.Component {
	render(){
		return (
			<div className="container" style={{marginBottom: "5rem"}}>
				<div className="row">
					<div className="col-12">
						{
							(this.props.competition == null || this.props.schoolsInfo == null) &&
							<Loader text="處理中，請稍後"/>
						}
						{
							this.props.schoolsInfo != null &&
							this.props.competition != null &&
							this.props.competition.length === 0 &&
							<EmptyCompetition />
						}
						{
							this.props.schoolsInfo != null &&
							this.props.competition != null &&
							this.props.competition.length === 1 &&
							<OneCompetition
								targetCompetition={this.props.targetSchool}
								targetSchoolName={this.props.targetSchoolName}

								groupIndexes={this.props.groupIndexes}
                tabListIndex={this.props.tabListIndex}
                playingGroupIndex={this.props.playingGroupIndex}
                setTabListIndex={this.props.setTabListIndex}

								fromUpdateTime={this.props.fromUpdateTime}
                isScrolledHeaderShow={this.props.isScrolledHeaderShow}
							/>
						}
						{
							this.props.schoolsInfo != null &&
							this.props.competition != null &&
							this.props.competition.length > 1 &&
							<OneMoreCompetition
								competition={this.props.competition}
								targetCompetition={this.props.targetSchool}
								targetSchoolName={this.props.targetSchoolName}

								schoolsInfo={this.props.schoolsInfo}
								setTargetSchool={this.props.setTargetSchool}
                setTabListIndex={this.props.setTabListIndex}

								groupIndexes={this.props.groupIndexes}
                tabListIndex={this.props.tabListIndex}
                playingGroupIndex={this.props.playingGroupIndex}
								fromUpdateTime={this.props.fromUpdateTime}
                isScrolledHeaderShow={this.props.isScrolledHeaderShow}
							/>
						}
					</div>
				</div>
			</div>
		)
	}
}
