import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

import agent from '../../agent/agent'
import FormCreate from '../../component/Form/FormCreate'
import { Loader } from '../../utils/component/component'
import { getFormList } from '../../action/form'

const isEmpty = require('lodash.isempty')

class FormCreateContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: this.props.userInfo.name,
      phone: this.props.userInfo.phone,
      email: this.props.userInfo.email,
      school: this.props.userInfo.school.name,	// 就讀學校
      song: '',
      mic: 0,
      micStand: 0,
      guitar: 0,
      bass: 0,
      cajon: 0,
      KB: 0,
      chair: 0,
      other: '',
      effector: '',
      last5Code: '',
      members: [""],
      commonFieldValues: [],
      customFieldValues: [],

      targetSchool: null,
      schoolsInfo: null,

      competitionGroups: [],	// 所有比賽組別
      competitionGroup: 1,	// 選擇比賽的組別

      maxMic: 6,
      maxMicStand: 6,
      maxGuitar: 2,
      maxBass: 1,
      maxCajon: 1,
      maxKB: 1,
      numOfPartner: 6,
      isEffector: true,
      coverPhoto: null,
      commonField: [],
      customField: [],
      isECPay: false,
      fee: 0,

      isWaiting: false,
      isRedirectToHome: false,
      isRedirectToPayment: false,
      isPay: false,		// 繳費狀態

      activeStep: 0,

      paymentInfoHTML: null,
      paymentInfo: null,
      formId: null,
    }
    this.setTargetSchool = this.setTargetSchool.bind(this)

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleGroupChange = this.handleGroupChange.bind(this)
    this.handleCommonFieldChange = this.handleCommonFieldChange.bind(this)
    this.handleCustomFieldChange = this.handleCustomFieldChange.bind(this)
    this.setMemberByGroupChange = this.setMemberByGroupChange.bind(this)

    this.handleAddMembers = this.handleAddMembers.bind(this)
    this.handleSubMeber = this.handleSubMeber.bind(this)
    this.handleMemberChange = this.handleMemberChange.bind(this)

    this.setWaitingState = this.setWaitingState.bind(this)
    this.setForm = this.setForm.bind(this)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.getPaymentInfo = this.getPaymentInfo.bind(this)

    this.handleNext = this.handleNext.bind(this)
    this.handleBack = this.handleBack.bind(this)
    this.handleRedirect = this.handleRedirect.bind(this)
  }

  componentWillMount() {
    const router = this.props.location
    const { schoolsInfo } = this.props
    const pathname = router.pathname.split('/')
    const targetSchoolId = pathname[2]
    const targetSchool = schoolsInfo ? schoolsInfo.find(school => school.id === targetSchoolId) : null
    if (schoolsInfo && targetSchoolId && targetSchool) {
      this.setTargetSchool(targetSchool)
    } else {
      this.setState({ isRedirectToHome: true })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    /** 取得繳費代碼 **/
    if (prevState.paymentInfoHTML !== this.state.paymentInfoHTML)
      document.getElementById("_form_aiochk").submit()
  }

  /** 設定最大上限值 **/
  setTargetSchool(targetSchool) {
    let groups = []
    for (const key in targetSchool.groupConfig) {
      if (targetSchool.groupConfig[key].isOpen)
        groups.push(key)
    }

    const competitionGroup = groups[0]

    this.setState({
      targetSchool: targetSchool,
      competitionGroups: groups,
      competitionGroup: competitionGroup,
      coverPhoto: targetSchool.coverPhotoL,
      commonField: targetSchool.commonField,
      isECPay: targetSchool.isECPay,
    })
    if (targetSchool.groupConfig[competitionGroup]) {
      this.setState({
        maxMic: targetSchool.groupConfig[competitionGroup].mic.max,
        maxMicStand: targetSchool.groupConfig[competitionGroup].micStand.max,
        maxGuitar: targetSchool.groupConfig[competitionGroup].guitar.max,
        maxBass: targetSchool.groupConfig[competitionGroup].bass.max,
        maxCajon: targetSchool.groupConfig[competitionGroup].cajon.max,
        maxKB: targetSchool.groupConfig[competitionGroup].KB.max,
        isEffector: targetSchool.groupConfig[competitionGroup].isEffector,
        numOfPartner: targetSchool.groupConfig[competitionGroup].numOfPartner,
        customField: targetSchool.groupConfig[competitionGroup].customField,
      })
    }
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleGroupChange(event) {
    const group = event.target.value
    const members = this.setMemberByGroupChange(group)

    if (this.state.targetSchool.groupConfig[group]) {
      this.setState({
        [event.target.name]: event.target.value,
        maxMic: this.state.targetSchool.groupConfig[group].mic.max,
        maxMicStand: this.state.targetSchool.groupConfig[group].micStand.max,
        maxGuitar: this.state.targetSchool.groupConfig[group].guitar.max,
        maxBass: this.state.targetSchool.groupConfig[group].bass.max,
        maxCajon: this.state.targetSchool.groupConfig[group].cajon.max,
        maxKB: this.state.targetSchool.groupConfig[group].KB.max,
        isEffector: this.state.targetSchool.groupConfig[group].isEffector,
        numOfPartner: this.state.targetSchool.groupConfig[group].numOfPartner,
        customField: this.state.targetSchool.groupConfig[group].customField,
        effector: this.state.targetSchool.groupConfig[group].isEffector ? this.state.effector : "",
        members: members,
      })
    }
  }

  handleAddMembers() {
    const members = this.state.members
    members.push("")
    this.setState({ members })
  }

  handleSubMeber(index) {
    const members = this.state.members
    if (members.length !== 1) {
      members.splice(index, 1)
    } else {
      members[index] = ""
    }
    this.setState({ members })
  }

  handleMemberChange(event, index) {
    const members = this.state.members
    members[index] = event.target.value
    this.setState({ members })
  }

  // 組別更換造成器材上限、繳費模式更動
  setMemberByGroupChange(group) {
    if (this.state.targetSchool.groupConfig[group].numOfPartner.length === 0) {
      return []
    } else {
      if (this.state.members.length > this.state.targetSchool.groupConfig[group].numOfPartner) {
        const newMembers = this.state.members.slice(0, this.state.targetSchool.groupConfig[group].numOfPartner)
        return newMembers.length === 0 ? [""] : newMembers
      } else {
        return this.state.members
      }
    }
  }

  handleCommonFieldChange(event, index) {
    const commonField = this.state.commonFieldValues
    commonField[index] = event.target.value
    this.setState({ commonFieldValues: commonField })
  }

  handleCustomFieldChange(event, index) {
    const customField = this.state.customFieldValues
    customField[index] = event.target.value
    this.setState({ customFieldValues: customField })
  }

  setForm() {
    const form = {}
    form.group = Number(this.state.competitionGroup)
    form.song = this.state.song
    form.organizer = this.state.targetSchool.id
    form.code = this.state.last5Code
    form.equipment = {
      mic: Number(this.state.mic),
      micStand: Number(this.state.micStand),
      guitar: Number(this.state.guitar),
      bass: Number(this.state.bass),
      cajon: Number(this.state.cajon),
      KB: Number(this.state.KB),
      chair: Number(this.state.chair),
      other: this.state.other,
      effector: this.state.effector
    }
    form.period = this.state.targetSchool.period
    form.commonField = !isEmpty(this.state.commonField) ?
      this.state.commonField.map((item, index) => {
        return ({
          ...item,
          isMultiLine: !!item.isMultiLine,
          value: this.state.commonFieldValues[index]
        })
      }) : []
    form.customField = !isEmpty(this.state.customField) ?
      this.state.customField.map((item, index) => {
        return ({
          ...item,
          isMultiLine: !!item.isMultiLine,
          value: this.state.customFieldValues[index]
        })
      }) : []
    form.partners = []

    for (const member of this.state.members) {
      // 防止全空字串
      if (member.trim().length !== 0) form.partners.push(member)
    }

    try {
      this.setState({
        fee: this.state.targetSchool.groupConfig[form.group]['feeSpecific'][form.partners.length] ||
          this.state.targetSchool.groupConfig[form.group]['fee']
      })
    } catch (error) {
      console.error(error)
      this.setState({ fee: this.state.targetSchool.groupConfig[form.group]['fee'] })
    }

    return form
  }

  async handleSubmit() {
    const form = this.setForm()
    const result = await agent.Form.create(form)
    if (result.status === 200) {
      const formId = await result.text()
      this.setState({ formId }, () => {
        this.props.getFormList()
        this.handleNext()
      })
    } else {
      alert("報名時發生錯誤！")
    }
    this.setState({ isWaiting: false })
  }

  async getPaymentInfo() {
    this.setState({ isWaiting: true })
    const body = {
      type: "form",
      data: {
        organizer: this.state.targetSchool.id,
        period: this.state.targetSchool.period,
        id: this.state.formId,
        group: this.state.competitionGroup
      }
    }
    await agent.ECPay.checkout(body)
      .then(result => result.text())
      .then(paymentInfoHTML => {
        this.setState({ paymentInfoHTML })
      })
      .catch(err => {
        console.error(err)
        this.setState({ isWaiting: false })
        alert("取得繳費代碼失敗！")
      })
  }

  setWaitingState() {
    this.setState({ isWaiting: true })
  }

  handleNext() { this.setState({ activeStep: this.state.activeStep + 1 }) }
  handleBack() { this.setState({ activeStep: this.state.activeStep - 1 }) }
  handleRedirect(pathParam) { this.setState({ [pathParam]: true }) }

  render() {
    if (!this.props.schoolsInfo || this.state.isRedirectToHome) {
      return <Redirect push to="/school" />
    }

    if (!this.state.targetSchool) {
      return <Loader text="處理中，請稍後。" />
    }

    if (this.state.paymentInfoHTML) {
      return (
        <div>
          <Loader text="繳費代碼成立中，請勿關閉頁面。" />
          {ReactHtmlParser(this.state.paymentInfoHTML)}
        </div>
      )
    }

    return (
      <FormCreate
        name={this.state.name}
        phone={this.state.phone}
        school={this.state.school}
        email={this.state.email}
        song={this.state.song}
        mic={this.state.mic}
        micStand={this.state.micStand}
        guitar={this.state.guitar}
        bass={this.state.bass}
        cajon={this.state.cajon}
        KB={this.state.KB}
        chair={this.state.chair}
        other={this.state.other}
        effector={this.state.effector}
        last5Code={this.state.last5Code}
        members={this.state.members}
        commonFieldValues={this.state.commonFieldValues}
        customFieldValues={this.state.customFieldValues}

        maxMic={this.state.maxMic}
        maxMicStand={this.state.maxMicStand}
        maxGuitar={this.state.maxGuitar}
        maxBass={this.state.maxBass}
        maxCajon={this.state.maxCajon}
        maxKB={this.state.maxKB}
        isEffector={this.state.isEffector}
        numOfPartner={this.state.numOfPartner}
        commonField={this.state.commonField}
        customField={this.state.customField}
        isECPay={this.state.isECPay}
        fee={this.state.fee}

        isWaiting={this.state.isWaiting}
        isPay={this.state.isPay}
        targetSchool={this.state.targetSchool}
        coverPhoto={this.state.coverPhoto}

        userInfo={this.props.userInfo}
        paymentInfo={this.state.paymentInfo}

        competitionGroups={this.state.competitionGroups}
        competitionGroup={this.state.competitionGroup}

        handleInputChange={this.handleInputChange}
        handleGroupChange={this.handleGroupChange}
        handleMemberChange={this.handleMemberChange}
        handleAddMembers={this.handleAddMembers}
        handleSubMeber={this.handleSubMeber}
        handleSubmit={this.handleSubmit}
        handleCommonFieldChange={this.handleCommonFieldChange}
        handleCustomFieldChange={this.handleCustomFieldChange}
        setWaitingState={this.setWaitingState}

        activeStep={this.state.activeStep}
        handleBack={this.handleBack}
        handleNext={this.handleNext}
        handleRedirect={this.handleRedirect}
        getPaymentInfo={this.getPaymentInfo}
      />
    )
  }
}

const mapStateToProps = state => ({
  formList: state.form.formList,
  userInfo: state.member.info,
  schoolsInfo: state.school.schoolsInfo,
})

const mapDispatchToProps = dispatch => ({
  getFormList: () => {
    dispatch(getFormList())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(FormCreateContainer)