import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import logoPanelBlack from "../../utils/icons/logo_panel-black.svg";

const PaymentInfo = (props) => {
  return (
    <div>
      <div>
        <div className="my-1">
          以下為您的「轉帳資訊」，請於繳款期限前，選擇任何一部銀行或郵局的 ATM
          自動提款機轉帳，輸入下方轉帳帳號及金額，即可完成付款。
        </div>
        <div className="my-1">
          轉帳完成後，您將會收到「樂台計畫」官方帳號的繳費完成推播訊息。
        </div>
        <div className="my-1">
          您可至 <b>【我】-【報名資訊】</b> 修改報名資訊以及確認繳費狀態。
        </div>
        <div className="my-1">
          <span role="img" aria-label="Alert">
            ⚠
          </span>
          ️提醒您，若於繳款期限到期仍未繳納，您的報名資格將被取消。
        </div>
      </div>
      <div className="border-bottom my-2" />
      <div>
        <div>
          <span>銀行代碼：</span>
          <b>{props.payment.bankCode}</b>
        </div>
        <div>
          <span>轉帳帳號：</span>
          <b>{props.payment.vAccount}</b>
        </div>
        <div>
          <span>轉帳金額：</span>
          <b>${props.payment.tradeAmt}</b>
        </div>
        <div>
          <span>繳款期限：</span>
          <b>{dayjs(props.payment.expireDate).format("YYYY-MM-DD")}</b>
        </div>
      </div>
    </div>
  );
};

export default class FormCheckout extends React.Component {
  render() {
    return (
      <div>
        <div className="bg-cover gradient-secondary top-fixed" />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="form-card">
                <div className="mcip-logo">
                  <img alt="brand" src={logoPanelBlack} />
                </div>
                <h5 className="success-title">
                  <div className="icon">
                    <FontAwesomeIcon icon="check-circle" />
                  </div>
                  <div className="text">完成囉</div>
                </h5>
                <PaymentInfo payment={this.props.payment} />
                <div
                  className="my-4 gradient-btn secondary"
                  onClick={() => this.props.redirect("isRedirectToFormList")}
                >
                  前往報名資訊！
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
