import agent from './agent'

export const School = {
	getSchoolsInfo: async () => {
		return new Promise((resolve, reject) => {
			return agent.Request.get(`/firestoreSchool`)
				.then(response => resolve(response))
		})
	},
	getLotteryInfo: async (schoolId, period) => {
		return new Promise((resolve, reject) => {
			return agent.Request.get(`/firestoreSchool/lottery?schoolId=${schoolId}&period=${period}`)
				.then(response => resolve(response))
		})
	},
	attendLottery: async body => {
		return new Promise((resolve, reject) => {
			return agent.Request.post(`/firestoreSchool/lottery`, body)
				.then(response => resolve(response))
		})
	},
	vote: async body => {
		return new Promise((resolve, reject) => {
			return agent.Request.post(`/firestoreSchool/popular`, body)
				.then(response => resolve(response))
		})
	}
}