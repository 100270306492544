import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { ChevronLeft } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton';
import PaymentPolicyComponent from '../../utils/component/PaymentPolicy'
import { MCIP, FORTEST } from '../../config/environment'

class PaymentPolicy extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ECPaySchools: null
    }
    this.setECPaySchools = this.setECPaySchools.bind(this)
  }

  componentDidMount() {
    this.setECPaySchools()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.schoolsInfo !== this.props.schoolsInfo) {
      this.setECPaySchools()
    }
  }

  setECPaySchools() {
    if (this.props.schoolsInfo) {
      const ECPaySchools = this.props.schoolsInfo.filter(school =>
        school.isECPay && school.id !== MCIP && school.id !== FORTEST
      )
      this.setState({ ECPaySchools })
    }
  }

  render() {
    return (
      <div className="page">
        <div className="container">
          <div className="top-bar">
            <IconButton component={Link} to={`/setting/about`} className="icon" >
              <ChevronLeft />
            </IconButton>
            <b className="title">金流服務條款</b>
          </div>
          <PaymentPolicyComponent ECPaySchools={this.state.ECPaySchools} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  schoolsInfo: state.school.schoolsInfo,
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPolicy)