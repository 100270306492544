import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Manual from '../../component/School/Manual'

class ManualContainer extends React.Component {
	constructor(props){
		super(props)
    this.handleRedirect = this.handleRedirect.bind(this)
	}

  handleRedirect(url){
    window.location = url
  }

	render(){
    if(!this.props.targetSchoolInfo){
      return <Redirect push to={`/school`} />
    }

		return (
			<Manual
				targetSchool={this.props.targetSchoolInfo}
        handleRedirect={this.handleRedirect}
			/>
		)
	}
}

const mapStateToProps = state => ({
  targetSchoolInfo: state.school.targetSchoolInfo,
})

const mapDispatchToProps = dispatch => ({ })

export default connect(mapStateToProps, mapDispatchToProps)(ManualContainer)