import agent from '../agent/agent'

export const RECEIVE_SCHOOLS_INFO = "RECEIVE_SCHOOLS_INFO"
export const SET_TARGET_SCHOOL = "SET_TARGET_SCHOOL"
export const CLEAR_TARGET_SCHOOL = "CLEAR_TARGET_SCHOOL"
export const RECEIVE_LOTTERY_INFO = "RECEIVE_LOTTERY_INFO"

export const getSchoolsInfo = () => dispatch => {
	return agent.School.getSchoolsInfo()
		.then(result => result.json())
		.then(resultJson => dispatch(receiveSchoolsInfo(resultJson)))
}

const receiveSchoolsInfo = infos => {
	return {
		type: RECEIVE_SCHOOLS_INFO,
		infos
	}
}

export const setTargetSchool = school => {
	return {
		type: SET_TARGET_SCHOOL,
		school
	}
}

export const clearTargetSchool = () => {
	return {
		type: CLEAR_TARGET_SCHOOL
	}
}

export const getLotteryInfo = (schoolId, period) => dispatch => {
	return agent.School.getLotteryInfo(schoolId, period)
		.then(result => result.json())
		.then(resultJson => dispatch(receiveLotteryInfo(resultJson)))
		.catch(err => dispatch(receiveLotteryInfo([])))
}

const receiveLotteryInfo = infos => {
	return {
		type: RECEIVE_LOTTERY_INFO,
		infos
	}
}